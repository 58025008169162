import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import { NgModule } from "@angular/core";
import { AuthGuard } from "./shared/auth/auth.guard";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "iap"
  },
  {
    path: "iap",
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    loadChildren: "./iap/iap.module#IAPModule"
  },
  {
    path: "**",
    component: PageNotFoundComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {}
