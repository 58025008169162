import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { LookupsService } from "app/iap/services/lookups.service";
import { MyProfileService } from "app/iap/services/my-profile.service";
import { IUser } from "app/shared/auth/auth.model";
import { AuthService } from "app/shared/auth/auth.service";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

// Create the agent data
export interface AgentData {
  agent: string;
  data: string;
  bold?: boolean;
}

// Populate table with some default data
const ELEMENT_DATA: AgentData[] = [
  { agent: "Company Name", data: "" },
  { agent: "Status", data: "", bold: true },
  { agent: "Certification Expiration Date", data: "" },
  { agent: "Authorized Countries of Recruitment", data: "" },
  { agent: "Director Region", data: "" },
  { agent: "Agency Since", data: "" }
];

@Component({
  selector: "app-iap-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.css"]
})
export class MyProfileComponent implements OnInit, OnDestroy {
  user: IUser;
  hasAccess: boolean = false;
  isAdmin: boolean = false; // We can data bind to this to pass it to child components
  displayedColumns: string[] = ["agent", "data"];
  dataSource = ELEMENT_DATA;
  agencySub: Subscription;
  countries: any = [];
  agentId: number;
  oldAgencyId: string;
  agencyId: string;
  authCountryArr = [];
  isLoading: boolean = true;

  constructor(
    private authService: AuthService,
    private myProfileService: MyProfileService,
    private lookupService: LookupsService
  ) {}

  ngOnInit() {
    this.user = this.authService.getUserInfo();

    this.loadCountries();

    // Checking for roles
    if (
      this.authService.hasRole("Agent") ||
      this.authService.hasRole("Agency_Admin")
    ) {
      this.hasAccess = true;
      if (this.authService.hasRole("Agency_Admin")) {
        this.isAdmin = true;
      }
    } else {
      this.hasAccess = false;
    }

    this.loadTable();
  }

  loadCountries() {
    // Use the lookup service to get Countries and Codes
    this.lookupService
      .getLookupByTableAlias("country")
      .pipe(take(1))
      .subscribe(country => {
        this.countries = country;
      });
  }

  loadTable() {
    // Load the Agency table subscribing the getAgency Service
    this.agencySub = this.myProfileService
      .getAgencyInformation(this.user.oid)
      .subscribe(
        agencyInfo => {
          if (
            !agencyInfo.authCountries ||
            agencyInfo.authCountries.length === 0
          ) {
            this.authCountryArr = ["NA"];
          } else {
            this.authCountryArr = agencyInfo.authCountries.split(",");
          }
          let authCountries = [];
          this.authCountryArr.map(countryCode => {
            if (countryCode === "NA") {
              authCountries.push("No Authorized Countries");
            }

            for (let i = 0; i < this.countries.length; ++i) {
              if (this.countries[i].Code === countryCode) {
                authCountries.push(this.countries[i].Name);
              }
            }
          });

          // Populate the table with data
          this.agencyId = agencyInfo.agencyId;
          this.oldAgencyId = agencyInfo.oldAgencyID;
          this.dataSource[0].data = agencyInfo.companyName;
          if (agencyInfo.status === "A") {
            this.dataSource[1].data = "Complete";
          } else if (agencyInfo.status === "B") {
            this.dataSource[1].data = "Probationary";
          } else {
            this.dataSource[1].data = "Undefined";
          }

          let expDate = new Date(Date.parse(agencyInfo.certExpiryDate));
          expDate.setDate(expDate.getDate() + 1);
          const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "short",
            day: "numeric"
          };
          this.dataSource[2].data = expDate.toLocaleDateString(
            undefined,
            options
          );
          this.dataSource[3].data = authCountries.join(", ");
          this.dataSource[4].data = agencyInfo.directorRegionName;
          let sinceDate = new Date(Date.parse(agencyInfo.agencySince));
          sinceDate.setDate(sinceDate.getDate() + 1);
          this.dataSource[5].data = sinceDate.toLocaleDateString(
            undefined,
            options
          );
          this.isLoading = false;
        },
        error => {
          console.log(error.message);
        }
      );
  }

  ngOnDestroy() {
    this.agencySub.unsubscribe();
  }
}
