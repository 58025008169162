import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApplicantsListService } from "app/iap/services/applicants-list.service";
import { ApplicationUpdatesService } from "app/iap/services/application-updates.service";

@Component({
  selector: "app-application-details",
  templateUrl: "./application-details.component.html",
  styleUrls: ["./application-details.component.scss"]
})
export class ApplicationDetailsComponent implements OnInit {
  data: StudentApplication = {
    iwa_application_number: "",
    student_number: "",
    agent_id: "",
    student_name: "",
    application_number: "",
    application_program_number: undefined,
    term: undefined,
    admit_term_description: "",
    program_code: "",
    program_description: "",
    campus: "",
    campus_description: "",
    action_date: "",
    reason: "",
    reason_description: "",
    acad_career: "",
    acad_level: "",
    offer_letter: "",
    readStatus: undefined
  };
  chatData = [];
  communicationPdfLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private applicationUpdatesService: ApplicationUpdatesService,
    private pdfservice: ApplicantsListService
  ) {
    this.communicationPdfLoading = false;
  }

  ngOnInit() {
    this.applicationUpdatesService
      .getSelectedApplication(this.route.snapshot.paramMap.get("id"))
      .subscribe(data => {
        this.data = data;
        if (this.data.offer_letter) {
          this.data.offer_letter = data.offer_letter.slice(0, 10);
        }

        const chatID = `${this.data.application_number}${this.data.program_code}${this.data.term}${this.data.campus}${this.data.acad_level}${this.data.acad_career}${this.data.application_program_number}`;
        this.applicationUpdatesService
          .getChatData(`${chatID}`)
          .subscribe(data => {
            this.chatData = data.records;
          });
      });
  }

  openOfferLetter(application: StudentApplication) {
    this.communicationPdfLoading = true;
    this.pdfservice
      .getOfferLetterPdf(
        application.student_number,
        application.application_number,
        application.application_program_number
      )
      .subscribe(offerLetterData => {
        console.log(offerLetterData);
        this.communicationPdfLoading = false;
        offerLetterData.forEach(pdfData => {
          const linkSource = "data:application/pdf;base64," + pdfData.FILEDATA;
          const downloadLink = document.createElement("a");

          const fileName =
            application.student_number +
            "-" +
            application.student_name +
            "-" +
            pdfData.FILENAME;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
      });
  }
}

export interface StudentApplication {
  iwa_application_number: string;
  student_number: string;
  agent_id: string;
  student_name: string;
  application_number: string;
  application_program_number: Number;
  term: Number;
  admit_term_description: string;
  program_code: string;
  program_description: string;
  campus: string;
  campus_description: string;
  action_date: string;
  reason: string;
  reason_description: string;
  acad_career: string;
  acad_level: string;
  offer_letter: string;
  readStatus: Number;
}
