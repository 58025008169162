import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { fromPromise } from "rxjs-compat/observable/fromPromise";
import { IUser } from "../auth/auth.model";
import { AuthService } from "../auth/auth.service";
import { StateService } from "./state.service";

@Injectable({
  providedIn: "root"
})
export class IapHttpInterceptorService implements HttpInterceptor {
  appId: string = null;

  constructor(
    private authService: AuthService,
    private stateService: StateService
  ) {}

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler,
    agentId: string,
    appId: string
  ): Promise<HttpEvent<any>> {
    const headerSettings: { [name: string]: string | string[] } = {};
    let token: string;
    let isUserAdmin: boolean = false;

    if (this.authService.getCacheAccessToken()) {
      let numberTime = Number(this.authService.getCacheAccessToken().expiresIn);
      let currentNumberTime = Math.floor(Date.now() / 1000);
    } else {
      console.log("access token is null");
    }

    if (
      this.authService.getCacheAccessToken() &&
      Number(this.authService.getCacheAccessToken().expiresIn) >
        Math.floor(Date.now() / 1000)
    ) {
      token = this.authService.getCacheAccessToken().accessToken;
    } else {
      if (environment.ssoEnabled) {
        let authResponse = await this.authService.acquireToken();
        token = authResponse.accessToken;
      } else {
        token = await this.authService.acquireToken();
      }
    }

    if (token) {
      let user = this.authService.getUserInfo() as IUser;
      if (environment.ssoEnabled) {
        if (user.rolesOnly) {
          isUserAdmin = user.rolesOnly.includes("Admin");
        }
      }

      agentId = this.stateService.agentId;

      headerSettings["Authorization"] = "Bearer " + token;
    }

    if (environment.apiSubscriptionEnabled) {
      headerSettings[environment.apiSubscriptionKeyName] =
        environment.apiSubscriptionKey;
    }

    if (appId) {
      headerSettings["appId"] = appId;
    }

    if (agentId) {
      headerSettings["agentId"] = agentId;

      if (environment.ssoEnabled) {
        headerSettings["isAdmin"] = `${isUserAdmin}`;
      }
    }

    return next
      .handle(
        request.clone({
          setHeaders: headerSettings
        })
      )
      .toPromise();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return fromPromise(
      this.handleAccess(
        req,
        next,
        this.stateService.getAgentId(),
        this.stateService.getAppId()
      )
    );
  }
}
