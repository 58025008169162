import { Component, OnInit } from "@angular/core";
import { ContactData } from "./seneca-contacts.model";

@Component({
  selector: "app-seneca-contacts",
  templateUrl: "./seneca-contacts.component.html",
  styleUrls: ["./seneca-contacts.component.scss"]
})
export class SenecaContactsComponent implements OnInit {
  contactSource;

  contactInfo: ContactData[] = [
    {
      country: "Cambodia",
      jobTitle: "Director, Regional Business Development",
      contactDetails: {
        name: "Michelle Gottheil",
        email: "michelle.gottheil@senecacollege.ca",
        phone: "+1 (416) 491-5050 Ext. 22345"
      }
    },

    {
      country: "",
      jobTitle: "Business Development Manager",
      contactDetails: {
        name: "Michelle Gottheil",
        email: "michelle.gottheil@senecacollege.ca",
        phone: "+1 (416) 491-5050 Ext. 22345"
      }
    },

    {
      country: "",
      jobTitle: "Recruitment & Agency Coordinator",
      contactDetails: {
        name: "Shirley Mangallon",
        email: "shirley.mangallon@senecacollege.ca",
        phone: "+1 (416) 491-5050 Ext. 22265"
      }
    },
    {
      newLine: true
    },
    {
      country: "Singapore",
      jobTitle: "Director, Regional Business Development",
      contactDetails: {
        name: "Nick Huang",
        email: "nick.huang@senecacollege.ca",
        phone: "+1 (416) 491-5050 Ext. 22375"
      }
    },
    {
      country: "",
      jobTitle: "Business Development Manager",
      contactDetails: {
        name: "Damon Nguyen",
        email: "damon.nguyen@senecacollege.ca",
        phone: "+1 (416) 491-5050 Ext. 22837"
      }
    },
    {
      country: "",
      jobTitle: "Recruitment & Agency Coordinator",
      contactDetails: {
        name: "Shirley Mangallon",
        email: "shirley.mangallon@senecacollege.ca",
        phone: "+1 (416) 491-5050 Ext. 22265"
      }
    },
    {
      newLine: true
    },
    {
      country: "viet nam",
      jobTitle: "Director, Regional Business Development",
      contactDetails: {
        name: "Prashant Srivastava",
        email: "prashant.srivastava@senecacollege.ca",
        phone: "+1 (416) 491-5050 Ext. 22492"
      }
    },
    {
      country: "",
      jobTitle: "Business Development Manager",
      contactDetails: {
        name: "Prashant Srivastava",
        email: "prashant.srivastava@senecacollege.ca",
        phone: "+1 (416) 491-5050 Ext. 22492"
      }
    },
    {
      country: "",
      jobTitle: "Recruitment & Agency Coordinator",
      contactDetails: {
        name: "Urmila Chunoo",
        email: "urmila.chunoo@senecacollege.ca",
        phone: "+1 (416) 491-5050 Ext. 22626"
      }
    }
  ];

  constructor() {}

  ngOnInit() {}
}
