export interface IApplicant {
  Id: number;
  LastName: string;
  FirstName: string;
  Phone: string;
  Email: string;
}

export class Agent {
  Id: number;
  LastName: string;
  FirstName: string;
  Phone: string;
  Email: string;
}
export class ContactRequest {
  personalData: PersonalData;
  requestType: any = "";
  text: string = "";
}

export class PersonalData {
  email: string = "";
  mobile: string = "";
  country: string = "";
}

export class PriceDetails {
  PId: number;
  MonthlyPrice: number;
  NumberOfUsers: number;
  Data: string;
  AdditionalSupport: string;
}

/* These are the data types for the resource's pages */

export interface IAgentResource {
  id: number;
  statusId: number;
  priorityId: number;
  sourceId: number;
  displayName: string;
  modifiedBy: string;
  createdBy: string;
  publishDate: string;
  description: string;
  link: string;
  fileName: string;
  fileLocation: string;
  isDeleted: boolean;
}

export class AgentRequestForMaterial {
  programList: string;
  englishLanguageInstitute: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  addressLine1: string;
  addressLine2: string;
  country: string;
  province: string;
  city: string;
  requestedBy: string;
  requestedDate: string;
  postalCode: string;
}

export interface ICountry {
  code: string;
  name: string;
  provinceList?: IState[];
}

export interface IState {
  code: string;
  name: string;
}
