/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./snackbar-success.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./snackbar-success.component";
import * as i5 from "@angular/material/snack-bar";
var styles_SnackbarSuccessComponent = [i0.styles];
var RenderType_SnackbarSuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SnackbarSuccessComponent, data: {} });
export { RenderType_SnackbarSuccessComponent as RenderType_SnackbarSuccessComponent };
export function View_SnackbarSuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "align-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["check_circle_outline"])), (_l()(), i1.ɵted(4, null, [" \u00A0 \u00A0 ", " ", "\n"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = ((_co.data == null) ? null : _co.data.message); var currVal_3 = ((_co.showSuccessfully === true) ? "successfully" : ""); _ck(_v, 4, 0, currVal_2, currVal_3); }); }
export function View_SnackbarSuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-snackbar-success", [], null, null, null, View_SnackbarSuccessComponent_0, RenderType_SnackbarSuccessComponent)), i1.ɵdid(1, 49152, null, 0, i4.SnackbarSuccessComponent, [i5.MatSnackBarRef, i5.MAT_SNACK_BAR_DATA], null, null)], null, null); }
var SnackbarSuccessComponentNgFactory = i1.ɵccf("app-snackbar-success", i4.SnackbarSuccessComponent, View_SnackbarSuccessComponent_Host_0, {}, {}, []);
export { SnackbarSuccessComponentNgFactory as SnackbarSuccessComponentNgFactory };
