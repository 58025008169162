import { Injectable, OnDestroy } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment
} from "@angular/router";
import { MsalGuard, MsalService } from "@azure/msal-angular";
import { Observable } from "rxjs";
import { AuthService } from "../../../shared/auth/auth.service";

@Injectable({
  providedIn: "root"
})
export class UsersGuard implements CanActivate, CanLoad, OnDestroy {
  constructor(private authService: AuthService) {}

  // TODO:This function can be made simpler by separating the logic for initial login authentication, applicant authentication and agent authentication into 3 separate guards
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.authService.isAgencyAdmin()) {
      return true;
    } else {
      return false;
    }
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (this.authService.isAgencyAdmin()) {
      return true;
    } else {
      return false;
    }
  }
  ngOnDestroy(): void {}
}
