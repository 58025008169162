import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material";
import { ActivatedRoute, Params } from "@angular/router";
import { RequestsSubmittedComponent } from "./submitted-requests/submitted-requests.component";
import { NewRequestsComponent } from "./new-requests/new-requests.component";
import { Subject } from "rxjs";

@Component({
  selector: "app-iap-commissions",
  templateUrl: "./commissions.component.html",
  styleUrls: ["./commissions.component.css"]
})
export class CommissionsComponent implements OnInit 
{
  
  commissionsDetails: boolean = false;

  @ViewChild("tab") tabsControl: MatTabGroup;

  @ViewChild(RequestsSubmittedComponent)
  private requestsSubmittedComponent: RequestsSubmittedComponent;

  @ViewChild(NewRequestsComponent)
  private newRequestsComponent: NewRequestsComponent;

  loading:boolean;
  
  public tabParentValue: any;

  constructor(
    private route: ActivatedRoute,
    private change: ChangeDetectorRef
  ) {}

  ngOnInit() 
  {
    setTimeout(()=>{
      this.loading=true;
    }, 500);

    let newRequestTerm = this.newRequestsComponent.getSelectedTerm();
    let requestSubmittedTerm = this.requestsSubmittedComponent.getSelectedTerm();

    this.tabParentValue = {term0: newRequestTerm, term1:requestSubmittedTerm};

    let parameters = window.history.state.parameters;

    let index = 0;
    
    if (parameters)
    {
      index = parameters.index;
      //let index = params.queryParams;

      if (index) 
      {
        this.tabsControl.selectedIndex = index;
      } 
      else {
        this.tabsControl.selectedIndex = 0;
      }
    }

    /*
    this.route.params.subscribe((params: Params) => 
    {
      let index = parameters.index;
      //let index = params.queryParams;

      if (index) {
        this.tabsControl.selectedIndex = index;
      } else {
        this.tabsControl.selectedIndex = 0;
      }
    });
    */
  }

  onTabChanged(event: MatTabChangeEvent) {

    let newRequestTerm = this.newRequestsComponent.getSelectedTerm();
    let requestSubmittedTerm = this.requestsSubmittedComponent.getSelectedTerm();

    this.tabParentValue = {term0: newRequestTerm, term1:requestSubmittedTerm};

    if (event.index == 0) {

      this.newRequestsComponent.refresh(this.tabParentValue);

    }
    else if (event.index == 1) {     

      this.requestsSubmittedComponent.refresh(this.tabParentValue);
    }
  }

  commissionsDetail(value: boolean) {
    this.commissionsDetails = value;
  }

  handleChildEmitter(event:any){
    //console.log(event.value)
    this.loading=event.loading;
  }
}
