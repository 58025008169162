import { Configuration } from "msal";
import { commonEnv } from "./environment.common";

const instrumentationKey = commonEnv.ssoEnabled ? "" : "";
const clientId = commonEnv.ssoEnabled
  ? "e1fe2595-78d6-4211-a55a-83bde943750c"
  : "e1fe2595-78d6-4211-a55a-83bde943750c";
const tenant = commonEnv.ssoEnabled
  ? "iaptstb2c.onmicrosoft.com"
  : "iaptstb2c.onmicrosoft.com";
const scopes = commonEnv.ssoEnabled
  ? [clientId]
  : ["https://IAPTSTB2C.onmicrosoft.com/agent/read"];
const azureInstance = commonEnv.ssoEnabled
  ? `https://iaptstb2c.b2clogin.com/tfp`
  : `https://iaptstb2c.b2clogin.com/tfp`;
const appUrl = "https://intl-agent-tst.senecapolytechnic.ca";
const agentSignInPolicy = "B2C_1A_SIGNIN_WITH_ROLES_ONLYLOCAL-FORCERESET";
// const agentSignInPolicy = "B2C_1A_signin_with_roles_ADDLOCAL-FORCERESET";
const applicantSignInPolicy = "B2C_1_signupsignin";
const resetPasswordPolicy = "B2C_1_resetpassword";

export class BuildEnvironment {
  constructor() {}

  public static GetEnvironmentUrl(): string {
    return environment.apiUrl;
  }
}

export const MSAL_CONFIGURATION: Configuration = {
  auth: {
    clientId: clientId,
    authority: `${azureInstance}/${tenant}/${agentSignInPolicy}`,
    validateAuthority: false,
    redirectUri: `${appUrl}`,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${appUrl}/logout`
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

export const environment = {
  //Phase 2 - Policy settings - starts 2
  ssoEnabled: commonEnv.ssoEnabled ? commonEnv.ssoEnabled : false,
  test_roles: commonEnv.ssoEnabled ? false : false,
  roles_case_1: commonEnv.ssoEnabled ? "Admin-ReadWrite" : "",
  roles_case_2: commonEnv.ssoEnabled
    ? "Agent-ResourcesPageReadOnly, Agent-HideApplicationButton"
    : "",
  //Phase 2 - Policy settings - ends 2
  production: true,
  appUrl: appUrl,
  scopes: scopes,
  clientId: clientId,
  apiUrl:
    "https://seneapi-apims-tst.azure-api.net/agentportal/public/controllers/api",
  apiSubscriptionKeyName: "AGNTPRTL-Apim-Subscription-Key",
  apiSubscriptionKey: "a73d2cb541b04764995335319ec85e90",
  apiSubscriptionEnabled: true,
  agentSignInPolicy: agentSignInPolicy,
  applicantSignInPolicy: applicantSignInPolicy,
  resetPasswordPolicy: resetPasswordPolicy,
  azureInstance: azureInstance,
  // Phase 2 - Policy settings - starts 3
  appInsights: {
    instrumentationKey: instrumentationKey
  },
  // Phase 2 - Policy settings - ends 3
  tenant: tenant,
  msalAgentConfigs: {
    clientID: clientId,
    authority: `${azureInstance}/${tenant}/${agentSignInPolicy}`,
    validateAuthority: false,
    consentScopes: scopes,
    redirectUri: `${appUrl}`,
    cacheLocation: commonEnv.ssoEnabled ? "localStorage" : "sessionStorage",
    //Phase 1 - Policy settings - starts 2
    //cacheLocation: "sessionStorage",
    //Phase 1 - Policy settings - starts 2
    // Phase 2 - Policy settings - starts 4
    //cacheLocation: "localStorage",
    // Phase 2 - Policy settings - ends 4
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${appUrl}/logout`,
    storeAuthStateInCookie: true
  },
  iwaAppUrl: "https://iap-tst.senecapolytechnic.ca/agent"
};
