import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CommissionService } from "app/iap/services/commission.service";
import { DialogService } from "app/iap/services/dialog.service";
import { UserService } from "app/iap/services/user.service";
import { AuthService } from "app/shared/auth/auth.service";
import { FormValidationService } from "app/shared/services/form-validation.service";
import { BehaviorSubject, Subject } from "rxjs";
import { MatSnackBar} from "@angular/material";

@Component({
  selector: "app-request-for-material",
  templateUrl: "./disputed-request-all.component.html",
  styleUrls: ["./disputed-request-all.component.scss"]
})
export class DisputedRequestAllComponent implements OnInit, AfterViewChecked, OnDestroy {
  public agentResourceForm: FormGroup;
  public selectedValue: string;
  public agencyID: string;
  public createdByID: string;
  public agentsList: string[];
  public programCodeList: any[];
  public termsList: any[];
  public campusList: any[];
  public displayedCode:any[];
  public selectedTermOption:string;

  public differenceLength = new BehaviorSubject(0);

  missingInfo:any;
  chosenProgram:string='';
  loadingFlag:boolean=false;

  //let observable know when to unsubscribe, will be marked as completed whenever the form is destroyed
  destroyed$ = new Subject();

  constructor
  (
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private commissionService: CommissionService,
    private dialogService: DialogService,
    private authService: AuthService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private cdref : ChangeDetectorRef
  ) 
  {
    this.commissionService.getProgramCodes().subscribe(data => {
      this.programCodeList = data.recordset;
    });

    this.commissionService.getTerms().subscribe(data => {
      this.termsList = data.recordset;
    });

    this.commissionService.getProgramCampuses().subscribe(data => {
      this.campusList = data.recordset;
    });
  }

  public ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.agencyID = params["agencyID"];

      this.commissionService
        .getAgentsByAgencyID(this.agencyID)
        .subscribe(data => {
          this.agentsList = data;
        });      

      let userInfo = this.authService.getUserInfo();
      this.userService.getAgentAndAgencyID(userInfo.oid).subscribe(data => {
        this.createdByID = data.UserID;
      });

      this.initForm();

      this.agentResourceForm.controls["commentsCtrl"].valueChanges.subscribe(
        v => {
          if (v)
          {
            this.differenceLength.next(500 - v.length);
          }
        }
      );
    });
  }

  ngAfterViewChecked(){

    this.cdref.detectChanges();

  }

  private initForm(): void {
    this.agentResourceForm = this.formBuilder.group({
      agentNameListCtrl: [""],
      studentNumberCtrl: ["", Validators.pattern(/^[^;]*$/)],
      applicationNumberCtrl: ["", Validators.pattern(/^[^;]*$/)],
      termCtrl: [""],
      studentLastNameCtrl: ["", Validators.pattern(/^[a-zA-Z]{2,}/)],
      studentFirstNameCtrl: ["", Validators.pattern(/^[a-zA-Z]{2,}/)],
      programCodeCtrl: ["", Validators.pattern(/^[a-zA-Z]{2,}/)],
      programNameCtrl: ["", Validators.pattern(/^[a-zA-Z]{2,}/)],
      programCampusCtrl: [""],
      commentsCtrl: [""],
      tuitionAmountCtrl: ["", Validators.pattern(/^\d*\.?\d*$/)]
    });
  }

  public onAppIDAndCode()
  {
    //making sure the request doesn't happen if we already have the data we need

    if(this.agentResourceForm.value.applicationNumberCtrl || this.agentResourceForm.value.studentNumberCtrl)
    {
      if 
      (
        !this.missingInfo || 
        this.missingInfo[0].Application_Id!=this.agentResourceForm.value.applicationNumberCtrl ||
        this.missingInfo[0].Student_Number!=this.agentResourceForm.value.studentNumberCtrl
      )
      {
        this.loadingFlag = true;
        let paramToUse;

        if 
        (
          !this.agentResourceForm.value.applicationNumberCtrl || 
          this.missingInfo && this.missingInfo[0].Application_Id == this.agentResourceForm.value.applicationNumberCtrl
        )
        {
          paramToUse = {studentNumberCtrl: this.agentResourceForm.value.studentNumberCtrl};
        }
        else
        {
          paramToUse = {applicationNumberCtrl: this.agentResourceForm.value.applicationNumberCtrl}
        }

        this.commissionService
        .getMissingFromAppIDandCode(paramToUse)
        .subscribe
        (result => 
        {
            if (result =="NO MATCHING ID" && this.agentResourceForm.value.applicationNumberCtrl)
            {
              this.dialogService.MessageBox(
                "No matching Application ID in database",
                "Informational",
                5000,
                "ErrorMessage")
                this.loadingFlag=false;
                this.agentResourceForm.patchValue({applicationNumberCtrl:''})
            }
            else if (result == "NO CODE WITH ID" && this.agentResourceForm.value.programCodeCtrl)
            {
              this.dialogService.MessageBox(
                "No matching Program Code for Application ID",
                "Informational",
                5000,
                "ErrorMessage")
                this.loadingFlag=false;
            }
            else if (result) 
            {
              this.missingInfo=result.recordset; 
              this.loadingFlag=false;

              this.agentResourceForm.patchValue({
                studentNumberCtrl: this.missingInfo[0].Student_Number,
                applicationNumberCtrl: this.missingInfo[0].Application_Id,
                termCtrl: this.termsList[0].Term,
                studentLastNameCtrl: this.missingInfo[0].Last_name,
                studentFirstNameCtrl: this.missingInfo[0].First_Name
              });
            } 
            else 
            {
              this.dialogService.MessageBox
              (
                "Error getting data",
                "X",
                5000,
                "ErrorMessage"
              );

              this.loadingFlag=false;
            }
        });
      }    
    }
  }

  public onSubmit() 
  {
    let test = this.selectedTermOption;

    this.loadingFlag = true;

    this.commissionService
    .getCommissionsSubmittedRequests(this.agencyID)
    .subscribe
    (
      data=>
      {
        if (data.findIndex(el=>el.ApplicationID == this.agentResourceForm.value.applicationNumberCtrl) > -1 )
        {
          console.log("THIS ALREADY HAS BEEN SUBMITTED");

          this.loadingFlag = false;

          this.dialogService.MessageBox(
            "A request for this student already exists. Please contact your Seneca Administrator.",
            "Informational",
            5000,
            "ErrorMessage"
          );
        }
        else
        {
          this.commissionService
          .getCommissionsNewRequests(this.agencyID)
          .subscribe
          (
            data=>
            {
              if (data.findIndex(el=>el.ApplicationID==this.agentResourceForm.value.applicationNumberCtrl) > -1 )
              {
                this.loadingFlag = false;

                this.dialogService.MessageBox(
                  "The submission cannot be completed. A new request for this student already exists.",
                  "Informational",
                  5000,
                  "ErrorMessage"
                );
              }
              else
              {
                this.commissionService
                .updateNotFoundRequest(this.createdByID, this.agentResourceForm.value)
                .subscribe(result =>
                {
                  this.loadingFlag = false;
                  
                  if (result) 
                  {
                    this.dialogService.MessageBox("Updated", "X", 1000, "SuccessMessage");
    
                    this.router.navigateByUrl("/iap/commissions");
                  } 
                  else 
                  {
                    this.dialogService.MessageBox(
                      "Error updating record",
                      "X",
                      5000,
                      "ErrorMessage"
                    );
                  }
                });
              }
            }
          )
        }      
      }
    )
  }

  public onCancel(): void {
    this.router.navigate(["/iap/commissions"]);
  }

  public DisplayErrorMessage(control: FormControl): string {
    return this.formValidationService.getErrorMessage(control);
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  public updateProgramFromCode(code:any){
    this.chosenProgram=this.programCodeList[this.programCodeList.findIndex(el=>el.ProgramCode==code.value)].PROG_DESCR

    this.agentResourceForm.patchValue({
      programNameCtrl:this.chosenProgram
    });
  }

  public updateProgramFromCampus(campus:any){
    this.displayedCode=this.campusList[this.campusList.findIndex(el=>el.Campus_DESC==campus.value)].Courses
    this.chosenProgram='';
    this.agentResourceForm.patchValue({
      programNameCtrl:'',
      programCodeCtrl:''

    });
  }

  // class ends here
}
