import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Component, OnInit, Inject } from "@angular/core";
import { MsalService } from "@azure/msal-angular";

export class CustomBox {
  constructor(
    public title: string,
    public message: string,
    public no: string,
    public yes: string
  ) {}
}

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"]
})
export class DialogComponent implements OnInit {
  title: string;
  message: string;
  no: string;
  yes: string;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomBox,
    private msalService: MsalService
  ) {
    this.title = data.title;
    this.message = data.message;
    this.no = data.no;
    this.yes = data.yes;
  }

  ngOnInit() {}

  onConfirm(): void {
    // Close the dialog, return true
    if (this.no === "logout") {
      this.msalService.logout();
    }
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
