import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

//---------services and models--------//
import { NewsfeedService } from "../../services/newsfeed.service";
import { LookupsService } from "../../services/lookups.service";
import { IStatus } from "../../models/lookup.model";
import { INewsfeed } from "../../models/newsfeed.model";
import { Location } from "@angular/common";

@Component({
  selector: "app-newsfeedview",
  templateUrl: "./newsfeedview.component.html",
  styleUrls: ["./newsfeedview.component.scss"]
})
export class NewsfeedviewComponent implements OnInit {
  onenewsfeed;
  editorReadSantStyle = {
    height: "100px",
    alignment: "MJ Selected-Quill"
  };

  constructor(
    private newsfeedService: NewsfeedService,
    private lookupService: LookupsService,
    private _activatedRoute: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    //----------for items-----------------//
    this._activatedRoute.paramMap.subscribe(params => {
      //---in the route we created edit route we set newsfeedid as param so we get it here---//
      const NewsfeedID = +params.get("newsfeedid");
      if (NewsfeedID) {
        //this.id = NewsfeedID;
        this.getNewsfeedByid(NewsfeedID);
      }
    });
  }

  getNewsfeedByid(id) {
    console.log("======XMan=======");
    this.newsfeedService.getNewsfeedById(id).subscribe(
      (newsfeed: INewsfeed) => this.displayNewsfeed(newsfeed),
      (err: any) => console.log("---error geting by id------" + err)
    );
  }

  displayNewsfeed(newsfeed: INewsfeed) {
    console.log(newsfeed);
    this.onenewsfeed = newsfeed[0];
  }

  goBack() {
    this.location.back();
  }
}
