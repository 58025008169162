import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CommissionService } from "app/iap/services/commission.service";
import { DialogService } from "app/iap/services/dialog.service";
import { UserService } from "app/iap/services/user.service";
import { AuthService } from "app/shared/auth/auth.service";
import { FormValidationService } from "app/shared/services/form-validation.service";
import { BehaviorSubject, Subject } from "rxjs";

@Component({
  selector: "disputed-request-submitted",
  templateUrl: "./disputed-request-submitted.component.html",
  styleUrls: ["./disputed-request-submitted.component.scss"]
})
export class DisputedRequestSubmittedComponent implements OnInit, OnDestroy {
  public agencyName:string;

  public applicationID: string;
  public agencyID: string;
  public RequestID: number;
  public agentID: number;
  public requestTypeID: string;
  public agentResourceForm: FormGroup;

  public agentsList: string[];
  public programCodesList: string[];
  public termsList: string[];
  public programCampusesList: string[];
  public selectedTermOption: string;
  public term0:string;

  public differenceLength = new BehaviorSubject(0);

  //let observable know when to unsubscribe, will be marked as completed whenever the form is destroyed
  destroyed$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private commissionService: CommissionService,
    private dialogService: DialogService,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.commissionService.getProgramCodes().subscribe(data => {
      this.programCodesList = data.recordset;
    });

    this.commissionService.getTerms().subscribe(data => {
      this.termsList = data.recordset;
    });

    this.commissionService.getProgramCampuses().subscribe(data => {
      this.programCampusesList = data.recordset;
    });
  }

  public ngOnInit() {
    this.agentResourceForm = this.formBuilder.group({
      agentNameListCtrl: [{value: "", disabled: true}],
      studentNumberCtrl: [""],
      applicationNumberCtrl: [""],
      termCtrl: [{value: "", disabled: true}],
      studentLastNameCtrl: [""],
      studentFirstNameCtrl: [""],
      programCodeCtrl: [""],
      programNameCtrl: [""],
      campusCtrl: [{value: "", disabled: true}],
      tuitionAmountCtrl: [""],
      commissionAmountCtrl: [""],
      commissionStatusCtrl: [""],
      commentsCtrl: [""],
      senecaCommentsCtrl: [""],
      tuitionAmountCtrl2: ["", Validators.pattern(/^\d*\.?\d*$/)]
    });

    this.agentResourceForm.controls["commentsCtrl"].valueChanges.subscribe(
      v => {
        if (v)
        {
          this.differenceLength.next(500 - v.length);
        }
      }
    );

    this.route.params.subscribe((params: Params) => {
      this.applicationID = params["id"];
      this.agencyID = params["agencyID"];
      this.requestTypeID = params["requestTypeID"];
      this.selectedTermOption = params["term"];
      this.term0 = params["term0"];

      this.commissionService
        .getAgentsByAgencyID(this.agencyID)
        .subscribe(data => {
          this.agentsList = data;
          this.commissionService
          this.agencyName=data[0].Agency;
           
        });

      let userInfo = this.authService.getUserInfo();

      this.userService.getAgentAndAgencyID(userInfo.oid).subscribe(data => {
        this.agentID = data.UserID;
      });

      this.initForm();
    });
  }

  // Intialize Form
  private initForm(): void {
    // If not a new form then populate with values

    // retrieve using the term selectedTermOption

    const request = this.commissionService
      .getCommissionSubmittedRequest(this.requestTypeID, this.applicationID,this.agencyID)
      .subscribe(data => {
        if (data) {
          this.applicationID = data.ApplicationID;
          this.RequestID = data.RequestID;

          this.agentResourceForm.patchValue({
            agentNameListCtrl: data.AgentName,
            studentNumberCtrl: data.SENECA_ID,
            applicationNumberCtrl: data.ApplicationID,
            termCtrl: data.Term,
            studentLastNameCtrl: data.StudentLastName,
            studentFirstNameCtrl: data.StudentFirstName,
            programCodeCtrl: data.ProgramCode,
            programNameCtrl: data.ProgramDescription,
            campusCtrl: data.ProgramCampus,
            tuitionAmountCtrl: data.TuitionAmount,
            commissionAmountCtrl: data.CommissionAmount,
            commissionStatusCtrl: data.CommissionStatusName,
            commentsCtrl: data.DisputtedComments,
            senecaCommentsCtrl:data.senecaComments,
            tuitionAmountCtrl2: data.DisputtedTuitionAmount
          });
        }
      });
  }

  public onCancel()
  {
    let parameters = {term0:this.term0, term1: this.selectedTermOption, index:1};  

    this.router.navigate(["/iap/commissions"], {state: {parameters: parameters}}); 
  }

  public onSubmit() {
    this.commissionService
      .updateRequest(this.RequestID, this.agentID, this.selectedTermOption, this.agentResourceForm.value)
      .subscribe(result => {
        if (result) {
          this.dialogService.MessageBox("Updated", "X", 1000, "SuccessMessage");

          //this.router.navigateByUrl("/iap/commissions");

          let parameters = {term0:this.term0, term1: this.selectedTermOption, index:1};  

          this.router.navigate(["/iap/commissions"], {state: {parameters: parameters}}); 

        } else {
          this.dialogService.MessageBox(
            "Error updating record",
            "X",
            5000,
            "ErrorMessage"
          );
        }
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  // class ends here
}
