<div class="seneca-container" id="header">

  <mat-toolbar class="seneca-header" color="primary" (click)="ToogleHeader()">
    <mat-toolbar-row class="seneca-header-row">
      <span class="header-left-item">
        <div *ngIf="!(isHandset$ | async)">
          <button mat-icon-button class="seneca-header-menu" (click)="ToggleSideNav()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div *ngIf="isHandset$ | async">
          <button mat-icon-button class="seneca-header-menu" (click)="ToggleSideNav()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>

        <div class="seneca-header-logo">
          <img src="../../../../assets/img/Seneca-International-Horizontal-Logo.svg" (click)="onHomeClick()" />
        </div>
      </span>
      <div class="header-right-item">
        <!-- AGENT PORTAL -->
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <mat-sidenav-container class="seneca-sidenav-container" autosize>
    <mat-sidenav #drawer [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'" opened="(isHandset$ | async)? false: true"
      [ngClass]="isExpanded? 'sideNavExpanded' : 'sideNavCollapsed'"> -->

  <mat-sidenav-container class="seneca-sidenav-container" autosize>


    <mat-sidenav #drawer [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="!(isHandset$ | async)"
      [ngClass]="isExpanded ? 'sideNavExpanded' : 'sideNavCollapsed'">
      <div>
        <mat-nav-list *ngFor="let link of navLinks">

          <mat-list-item *ngIf="!showPanelItem(link,isExpanded)" [ngClass]="isLinkActive(link.index)"
            [routerLink]="link.link" [routerLinkActive]="isLinkActive(link.index)" matTooltip="{{link.label}}"
            matTooltipPosition="right" [matTooltipDisabled]="isExpanded">
            <mat-icon mat-list-icon>{{link.iconName}}</mat-icon>
            <span mat-line *ngIf="isExpanded">{{link.label}}</span>
          </mat-list-item>

          <mat-expansion-panel class="mat-elevation-z0 " *ngIf="showPanelItem(link,isExpanded)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon mat-list-icon>{{link.iconName}}</mat-icon>
                <div class="mat-padding-left">
                  <span mat-line *ngIf="isExpanded">{{link.label}}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <span *ngFor="let childLink of link.children">
              <a [routerLink]="childLink.link" routerLinkActive>
                <mat-list-item>
                  <div class="mat-expansion-panel-padding-left"></div>
                  {{childLink.label}}
                </mat-list-item>
              </a>
            </span>
          </mat-expansion-panel>
        </mat-nav-list>

      </div>
      <span id="seneca-nav-img">
        <a href="https://www.senecacollege.ca/international.html" title="Seneca International" target="_blank"><img
            id="sidenav-img" src="../../../../assets/img/Seneca-Logo-Colored.svg" /></a>
      </span>
    </mat-sidenav>




    <mat-sidenav-content autosize>
      <ng-content></ng-content>
      
      <div id="footer">

          <div class="seneca-footer-row">
            <div class="seneca-footer-logo"><a href="https://www.senecacollege.ca/international.html"
                title="Seneca International" target="_blank">
                <img src="../../../../assets/img/Seneca-International-Horizontal-Logo-svg.svg" /></a>

              
            </div>
            <div class="seneca-footer-middle">
              <span>&copy; 2021. All rights reserved.</span>
            </div>


            <div class="seneca-footer-right">
              <a href="https://www.facebook.com/senecacollege" target="_blank" title="Facebook">
                <mat-icon svgIcon="facebook" (click)="openLink('https://www.facebook.com/senecacollege')"></mat-icon>
              </a>
              <a href="https://www.instagram.com/senecacollege/?hl=en" target="_blank" title="Instagram">
                <mat-icon svgIcon="instagram" (click)="openLink('https://www.instagram.com/senecacollege/?hl=en')">
                </mat-icon>
              </a>
              <a href="https://www.linkedin.com/school/seneca-college/" target="_blank" title="Linkedin">
                <mat-icon svgIcon="linkedin" (click)="openLink('https://www.linkedin.com/school/seneca-college/')">
                </mat-icon>
              </a>
              <a href="https://twitter.com/SenecaCollege" target="_blank" title="Twitter">
                <mat-icon svgIcon="twitter" (click)="openLink('https://twitter.com/SenecaCollege')"></mat-icon>
              </a>
              <a href="https://www.youtube.com/user/VideoSeneca" target="_blank" title="Youtube">
                <mat-icon svgIcon="youtube" (click)="openLink('https://www.youtube.com/user/VideoSeneca')"></mat-icon>
              </a>
            </div>

          </div>

      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>