import { Component, Inject } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material";

@Component({
  selector: "app-snackbar-success",
  templateUrl: "./snackbar-success.component.html",
  styleUrls: ["./snackbar-success.component.scss"]
})
export class SnackbarSuccessComponent {
  showSuccessfully = true;
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarSuccessComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data?: any
  ) {
    if (data.message.substring(0, 2) === "No" || data.action == "Informational") {
      this.showSuccessfully = false;
    }
  }
}
