import {
  CustomBox,
  DialogComponent
} from "../../iap/shared/dialog/dialog.component";

export function DisplayErrorMessage(
  dialog,
  dialogHeading,
  messages: string,
  no,
  yes
) {
  const resDialog = new CustomBox(dialogHeading, messages, no, yes);

  console.log("Launching erro dialog");

  const dialogRef = dialog.open(DialogComponent, {
    maxWidth: "600px",
    data: resDialog,
    disableClose: true
  });

  dialogRef.afterClosed().subscribe(dialogResult => {
    if (dialogResult) {
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
  });
}
