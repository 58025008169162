import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  FormControl,
  AbstractControl
} from "@angular/forms";
import { Router } from "@angular/router";
import { Helper } from "../../../../shared/common/helper";
import { FormValidationService } from "../../../../shared/services/form-validation.service";
import {
  AgentRequestForMaterial,
  ICountry,
  IState
} from "app/iap/models/applicant.model";
import {
  HttpServiceAgentRequestForMaterial,
  HttpServiceCountriesAndProvinces
} from "app/iap/services/iap-http.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-request-for-material",
  templateUrl: "./request-for-material.component.html",
  styleUrls: ["./request-for-material.component.scss"]
})
export class RequestForMaterialComponent implements OnInit, OnDestroy {
  public isLinear: boolean;
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public active: any;
  public agentResourceForm: FormGroup;
  public countryList: ICountry[];
  public permanentProvinceList: IState[];

  public centered: boolean;
  public disabled: boolean;
  public unbounded: boolean;
  public radius: number;
  public color: string;

  //let observable know when to unsubscribe, will be marked as completed whenever the form is destroyed
  destroyed$ = new Subject();

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private httpServiceAgentRequestForMaterial: HttpServiceAgentRequestForMaterial,
    private httpServiceCountriesAndProvinces: HttpServiceCountriesAndProvinces
  ) {
    this.centered = true;
    this.disabled = false;
    this.unbounded = false;
    this.radius = 25;
    this.color = "#da291c";

    this.countryList = [
      {
        code: "001",
        name: "Canada",
        provinceList: [
          { code: "011", name: "on" },
          { code: "012", name: "BC" }
        ]
      },
      {
        code: "002",
        name: "USA"
      },
      {
        code: "003",
        name: "UK"
      }
    ];
    this.permanentProvinceList = null;
    this.isLinear = true;

    this.httpServiceCountriesAndProvinces
      .getCountriesAndProvincesRequest(
        "getCountriesAndProvinces",
        "countriesList"
      )
      .subscribe(result => {
        this.countryList = result;
      });
  }

  public ngOnInit() {
    this.createFormGroup();

    this.loadAddressControl("thirdFormGroup", "countryCtrl");
  }

  public onSubmit() {
    if (this.agentResourceForm.valid) {
      let agentRequestForMaterial: AgentRequestForMaterial = this.createAgentRequestForMaterial();

      this.httpServiceAgentRequestForMaterial
        .postAgentRequestForMaterialRequest(
          "saveAgentRequestForMaterial",
          agentRequestForMaterial
        )
        .subscribe(
          results => {
            this.router.navigate(["iap/resources/thankyou"]);
          },
          err => {
            this.router.navigate(["iap/resources/thankyou"]);
          }
        );
    }
    /*
    else 
    {
      this.commonUtilsService.showErrors("Your data is invalid, please go back and fix the errors.");
    }
    */
  }

  public onCancel(): void {
    this.router.navigate(["iap/resources/"]);
  }

  public fillDefaultZipCode() {
    this.agentResourceForm
      .get("thirdFormGroup")
      .get("postalCodeCtrl")
      .setValue("00000");
  }

  public DisplayErrorMessage(control: FormControl): string {
    return this.formValidationService.getErrorMessage(control);
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  private loadAddressControl(
    controlName: string,
    dependentControlName: string
  ) {
    const address = this.agentResourceForm.get(controlName);
    const selectedCountry: AbstractControl = this.agentResourceForm
      .get(controlName)
      .get(dependentControlName);

    selectedCountry.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.loadProvinceListByCountry(address, selectedCountry, controlName);
      });
  }

  private loadProvinceListByCountry(
    addressType: AbstractControl,
    selectedCountry: AbstractControl,
    name1: string
  ) {
    this.permanentProvinceList = this.getProvinceListByCountry(
      addressType,
      selectedCountry,
      name1
    );

    //  = provinceList;
  }

  private getProvinceListByCountry(
    addressType: AbstractControl,
    selectedCountry: AbstractControl,
    name1: string
  ): IState[] {
    this.permanentProvinceList = [];

    if (this.countryList) {
      const countryByCode = this.countryList.find(
        country => country.code === selectedCountry.value
      );

      if (addressType === this.agentResourceForm.get(name1)) {
        if (countryByCode && countryByCode.provinceList) {
          this.permanentProvinceList = countryByCode.provinceList;
        }
      }
    }

    return this.permanentProvinceList;
  }

  private createAgentRequestForMaterial(): AgentRequestForMaterial {
    let agentRequestForMaterial: AgentRequestForMaterial = new AgentRequestForMaterial();

    agentRequestForMaterial.programList = this.agentResourceForm
      .get("firstFormGroup")
      .get("programListCtrl").value;
    agentRequestForMaterial.englishLanguageInstitute = this.agentResourceForm
      .get("firstFormGroup")
      .get("englishLanguageCtrl").value;

    agentRequestForMaterial.firstName = this.agentResourceForm
      .get("secondFormGroup")
      .get("firstNameCtrl").value;
    agentRequestForMaterial.lastName = this.agentResourceForm
      .get("secondFormGroup")
      .get("lastNameCtrl").value;
    agentRequestForMaterial.emailAddress = this.agentResourceForm
      .get("secondFormGroup")
      .get("emailCtrl").value;

    agentRequestForMaterial.addressLine1 = this.agentResourceForm
      .get("thirdFormGroup")
      .get("addressLine1Ctrl").value;
    agentRequestForMaterial.addressLine2 = this.agentResourceForm
      .get("thirdFormGroup")
      .get("addressLine2Ctrl").value;
    agentRequestForMaterial.country = this.agentResourceForm
      .get("thirdFormGroup")
      .get("countryCtrl").value;
    agentRequestForMaterial.province = this.agentResourceForm
      .get("thirdFormGroup")
      .get("provinceCtrl").value;
    agentRequestForMaterial.city = this.agentResourceForm
      .get("thirdFormGroup")
      .get("cityCtrl").value;
    agentRequestForMaterial.postalCode = this.agentResourceForm
      .get("thirdFormGroup")
      .get("postalCodeCtrl").value;

    return agentRequestForMaterial;
  }

  //creates the personalInfoForm form group for the html template
  private createFormGroup() {
    this.agentResourceForm = this.formBuilder.group({
      firstFormGroup: this.formBuilder.group({
        programListCtrl: Helper.CreateRequiredFormControl(),
        englishLanguageCtrl: Helper.CreateRequiredFormControl()
      }),
      secondFormGroup: this.formBuilder.group({
        firstNameCtrl: Helper.CreateSenecaOnlyLettersControl(2),
        lastNameCtrl: Helper.CreateRequiredSenecaOnlyLettersControl(2),
        emailCtrl: Helper.CreateRequiredEmailControl()
      }),
      thirdFormGroup: this.formBuilder.group({
        // addressLine1Ctrl: Helper.CreateSenecaOnlyLettersControl(1),
        addressLine1Ctrl: Helper.CreateSenecaOnlyLettersControl(1),
        addressLine2Ctrl: Helper.CreateSenecaOnlyLettersControl(3),
        countryCtrl: Helper.CreateRequiredFormControl(),
        provinceCtrl: Helper.CreateSimpleFormControl(),
        cityCtrl: Helper.CreateSenecaOnlyLettersControl(2),
        postalCodeCtrl: Helper.CreateSenecaOnlyLettersControl(1)
      })
    });
    console.log(this.agentResourceForm);
  }

  // class ends here
}
