import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
//---------dialog------------------/
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import {
  CustomBox,
  DialogComponent
} from "../../shared/dialog/dialog.component";

//---------service--------//
import { NewsfeedService } from "../../services/newsfeed.service";
import { INewsfeed } from "../../models/newsfeed.model";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

export interface PeriodicElement {
  title: string;
  headline: string;
  publishDate?: string;
}

@Component({
  selector: "app-newsfeed",
  templateUrl: "./newsfeed.component.html",
  styleUrls: ["./newsfeed.component.css"]
})
export class NewsfeedComponent implements OnInit {
  ELEMENT_DATA;
  //dataSource;

  News;
  NewsfeedList: INewsfeed[];
  //@ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatSort) sort: MatSort;
  //@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    "Status",
    "Title",
    "Headline",
    "Publishdate",
    "ModifiedBy",
    "delete"
  ];
  dataSource: MatTableDataSource<any>;

  constructor(
    private service: NewsfeedService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}
  ngOnInit() {
    this.refreshData();
    this.paginator.pageSize = 10;
  }

  refreshData() {
    this.service.getAllNewsfeed().subscribe(
      (newsfeed: INewsfeed[]) => {
        this.NewsfeedList = newsfeed;
        this.ELEMENT_DATA = newsfeed;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      error => {
        this.ErrorDialog(
          "Server Error",
          "Error Connecting to Server",
          "Close",
          "Refresh"
        );
      }
    );
  }

  deleteNewsfeed(id) {
    console.log(id);
    if (id > 0) {
      this.service.deleteNewsfeed(id).subscribe(
        res => {
          if (res) {
            this.MessageBox(
              "Record deleted successfully",
              "X",
              5000,
              "SuccessMessage"
            );
            // setTimeout(()=>{
            //   //this.callNext();
            //   this.callRedirect('../iap/newsfeed',4000);
            // },5000)
            this.callNext(4000);
          } else {
            this.MessageBox("Error Deleting", "X", 5000, "ErrorMessage");
          }
        },
        error => {
          this.ErrorDialog(
            "Server Error",
            "Error Connecting to Server",
            "Close",
            "Refresh"
          );
        }
      );
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  //--------------dialog-------//
  openDialog(id): void {
    const message = `Are you sure you want to delete?`;
    let no = "No";
    let yes = "Yes";
    const resDialog = new CustomBox("Confirm Action", message, no, yes);

    const dialogRef = this.dialog.open(DialogComponent, {
      maxWidth: "600px",
      data: resDialog
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.deleteNewsfeed(id);
      }
    });
  }

  public openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
      verticalPosition: "top",
      horizontalPosition: "center"
    });
  }

  public MessageBox(
    message: string,
    action: string,
    duration: number,
    BoxColorClass
  ) {
    this._snackBar.open(message, action, {
      duration: duration,
      verticalPosition: "top",
      horizontalPosition: "center",
      panelClass: [BoxColorClass]
    });
  }

  ErrorDialog(dialogHeading, messages: string, no, yes): void {
    const resDialog = new CustomBox(dialogHeading, messages, no, yes);

    const dialogRef = this.dialog.open(DialogComponent, {
      maxWidth: "600px",
      data: resDialog
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.callNext(200);
      }
    });
  }

  callNext(timeout) {
    setTimeout(() => {
      window.location.reload();
    }, timeout);
  }

  /*----Work to be done as planned-----*/
  callRedirect(route, time) {
    setTimeout(() => {
      this.router.navigate([route]);
    }, time);
  }
}
