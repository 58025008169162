import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  AbstractControl
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
//---------services and models--------//
import { NewsfeedService } from "../../../services/newsfeed.service";
import { LookupsService } from "../../../services/lookups.service";
import { IStatus } from "../../../models/lookup.model";
//-----------date formater----------------------//
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import * as _moment from "moment";
//import { default as _rollupMoment } from 'moment';
import { INewsfeed } from "../../../models/newsfeed.model";
//---------dialog------------------/
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import {
  CustomBox,
  DialogComponent
} from "../../../shared/dialog/dialog.component";

import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

//const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY"
  }
};

@Component({
  selector: "app-newsfeedform",
  templateUrl: "./newsfeedform.component.html",
  styleUrls: ["./newsfeedform.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class NewsfeedformComponent implements OnInit {
  NewsfeedForm: FormGroup;
  id: number = 0;
  data;
  status;
  lookuptablename;
  editorStyle = {
    height: "200px"
  };
  //----dialog---//
  result: string = "";

  constructor(
    private _formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private newsfeedService: NewsfeedService,
    private lookupService: LookupsService,
    private _activatedRoute: ActivatedRoute,
    private location: Location,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  //--------------dialog-------//
  openDialog(id): void {
    const message = `Are you sure you want to delete?`;
    let no = "No";
    let yes = "Yes";
    const resDialog = new CustomBox("Confirm Action", message, no, yes);

    const dialogRef = this.dialog.open(DialogComponent, {
      maxWidth: "600px",
      data: resDialog
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.deleteNewsfeed(id);
      }
    });
  }
  //---------init validation--------------------//
  StatusID = new FormControl("", [Validators.required]);
  //publishdate = new FormControl(moment());
  publishdate = new FormControl(_moment());
  ngOnInit() {
    let lookupTablename: string;
    this.lookupService
      .getLookupByTableAlias((lookupTablename = "status"))
      .subscribe(
        (istatus: IStatus) => {
          this.status = istatus;
        },
        error => {
          this.ErrorDialog(
            "Server Error",
            "Error Connecting to Server",
            "Close",
            "Refresh"
          );
        }
      );

    this.NewsfeedForm = this._formBuilder.group({
      NewsfeedID: [0, null],
      StatusID: ["", Validators.required],
      publishdate: ["", Validators.required],
      title: ["", Validators.required],
      headlineCtrl: ["", Validators.required],
      newsfeedContentCtrl: ["", null]
    });

    //----------for edit-----------------//
    this._activatedRoute.paramMap.subscribe(params => {
      //---in the route we created edit route we set id as param so we get it here---//
      const NewsfeedID = +params.get("newsfeedid");
      if (NewsfeedID) {
        this.id = NewsfeedID;
        this.getNewsfeedByid(NewsfeedID);
      }
    });
  }

  getNewsfeedByid(id) {
    this.newsfeedService.getNewsfeedById(id).subscribe(
      (newsfeed: INewsfeed) => this.editNewsfeed(newsfeed),
      error => {
        this.ErrorDialog(
          "Server Error",
          "Error Connecting to Server",
          "Close",
          "Refresh"
        );
      }
    );
  }
  editNewsfeed(newsfeed: INewsfeed) {
    this.NewsfeedForm.patchValue({
      NewsfeedID: newsfeed[0].NewsfeedID,
      StatusID: newsfeed[0].StatusID,
      title: newsfeed[0].Title,
      headlineCtrl: newsfeed[0].Headline,
      publishdate: newsfeed[0].Publishdate,
      newsfeedContentCtrl: newsfeed[0].NewsfeedContent
    });
  }

  newsfeedActions() {
    const result = this.NewsfeedForm.value;
    if (result.NewsfeedID > 0) {
      this.newsfeedService.updateNewsfeed(result, result.NewsfeedID).subscribe(
        res => {
          if (res) {
            this.MessageBox(
              "Record updated successfully",
              "X",
              3000,
              "SuccessMessage"
            );
            setTimeout(() => {
              this.callNext();
            }, 5000);
          } else {
            this.MessageBox("Error updating record", "X", 5000, "ErrorMessage");
          }
        },
        error => {
          this.ErrorDialog(
            "Server Error",
            "Error Connecting to Server",
            "Close",
            "Refresh"
          );
        }
      );
    } else {
      this.newsfeedService.insertNewsfeed(result).subscribe(
        res => {
          if (res) {
            this.MessageBox(
              "Record inserted successfully",
              "X",
              3000,
              "SuccessMessage"
            );
            this.callRedirect("../iap/newsfeed", 4000);
          } else {
            this.MessageBox("Error insert record", "X", 5000, "ErrorMessage");
          }
        },
        error => {
          this.ErrorDialog(
            "Server Error",
            "Error Connecting to Server",
            "Close",
            "Refresh"
          );
        }
      );
      //----call toester service to show message---//
    }
  }

  public openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
      verticalPosition: "top",
      horizontalPosition: "center"
    });
  }

  deleteNewsfeed(id) {
    if (id > 0) {
      this.newsfeedService.deleteNewsfeed(id).subscribe(
        res => {
          if (res) {
            this.MessageBox(
              "Record deleted successfully",
              "X",
              5000,
              "SuccessMessage"
            );
            this.callRedirect("../iap/newsfeed", 4000);
          } else {
            this.MessageBox("Error Deleting", "X", 5000, "ErrorMessage");
          }
        },
        error => {
          this.ErrorDialog(
            "Server Error",
            "Error Connecting to Server",
            "Close",
            "Refresh"
          );
        }
      );
    }
  }

  public ErrorMessage(message: string, action: string, duration: number) {
    this._snackBar.open(message, action, {
      duration: duration,
      verticalPosition: "top",
      horizontalPosition: "center"
    });
  }

  public MessageBox(
    message: string,
    action: string,
    duration: number,
    BoxColorClass
  ) {
    this._snackBar.open(message, action, {
      duration: duration,
      verticalPosition: "top",
      horizontalPosition: "center",
      panelClass: [BoxColorClass]
    });
  }

  ErrorDialog(dialogHeading, messages: string, no, yes): void {
    const resDialog = new CustomBox(dialogHeading, messages, no, yes);

    const dialogRef = this.dialog.open(DialogComponent, {
      maxWidth: "600px",
      data: resDialog
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.callNext();
      }
    });
  }

  callNext() {
    window.location.reload();
  }
  callRedirect(route, time) {
    setTimeout(() => {
      this.router.navigate([route]);
    }, time);
  }
}
