import { FormControl, Validators } from "@angular/forms";
import {
  senecaOnlyLetters,
  telephoneNumberValidator,
  senecaIsNum
} from "../services/form-validation.service";

export interface IPhone {
  countryCode: string;
  number: string;
}

export class PhoneNumber implements IPhone {
  constructor(public countryCode = "", public number = "") {}
}

export class Helper {
  public static CreateRequiredFormControl(): FormControl {
    return new FormControl(null, Validators.required);
  }

  public static CreateSimpleFormControl(): FormControl {
    return new FormControl(null);
  }

  public static CreateBooleanProperty(value: boolean): boolean[] {
    return [value];
  }

  public static CreateSenecaOnlyLettersControl(value: number) {
    return [null, senecaOnlyLetters(value)];
  }

  public static CreateRequiredSenecaOnlyLettersControl(value: number) {
    return [null, [Validators.required, senecaOnlyLetters(value)]];
  }

  public static CreatePhoneNumbersControl(value: number) {
    return [new PhoneNumber("", ""), telephoneNumberValidator(value)];
  }

  public static CreateRequiredEmailControl() {
    return [null, [Validators.required, Validators.email]];
  }

  public static CreateNumberInRange() {
    return [
      null,
      [Validators.maxLength(9), Validators.minLength(8), senecaIsNum]
    ];
  }

  public static GetContentType(fileExtension: string) {
    var contentType = "application/octet-stream";

    switch (fileExtension) {
      case "txt":
        contentType = "text/plain";
        break;
      case "docx":
        contentType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case "ppt":
      case "pptx":
        contentType = "application/vnd.ms-powerpoint";
        break;
      case "gif":
        contentType = "image/gif";
        break;
      case "jpeg":
      case "jpg":
        contentType = "image/jpeg";
        break;
      case "xls":
      case "xlsx":
        contentType = "application/vnd.ms-excel";
        break;
      case "avi":
        contentType = "a video/x-msvideo";
        break;
      case "bmp":
        contentType = "image/bmp";
        break;
      case "ico":
        contentType = "image/vnd.microsoft.icon";
        break;
      case "htm":
      case "html":
        contentType = "text/html";
        break;
      case "pdf":
        contentType = "application/pdf";
        break;
      case "png":
        contentType = "image/png";
        break;
      case "svg":
        contentType = "svg+xml";
        break;
      default:
        contentType = "application/octet-stream";
        break;
    }

    return contentType;
  }
}
