import { NgModule } from "@angular/core";
import { MaterialModule } from "../shared/material.module";
import { LayoutModule } from "@angular/cdk/layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MainNavComponent } from "./components/main-nav/main-nav.component";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { RouterModule } from "@angular/router";
import { GravatarModule } from "ngx-gravatar";
import { HttpCommonService } from "./services/http-common.service";
import { IconSnackBarComponent } from "./components/icon-snack-bar/icon-snack-bar.component";
import { SnackbarSuccessComponent } from "./components/snackbar-success/snackbar-success.component";
import { ProgressSpinnerComponent } from "./components/progress-spinner/progress-spinner.component";

@NgModule({
  declarations: [
    MainNavComponent,
    IconSnackBarComponent,
    SnackbarSuccessComponent,
    ProgressSpinnerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    LayoutModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    ScrollingModule,
    GravatarModule
  ],
  providers: [HttpCommonService],
  entryComponents: [IconSnackBarComponent, SnackbarSuccessComponent],
  exports: [
    MainNavComponent,
    CommonModule,
    MaterialModule,
    LayoutModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    ScrollingModule,
    GravatarModule,
    SnackbarSuccessComponent,
    ProgressSpinnerComponent
  ]
})
export class SharedModule {}
