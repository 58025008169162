import { OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { AuthService } from "../auth/auth.service";
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
var StateService = /** @class */ (function () {
    function StateService(authService) {
        this.authService = authService;
        this.destroyed$ = new Subject();
    }
    StateService.prototype.ngOnInit = function () { };
    StateService.prototype.setUserInfo = function (userInfo) {
        this.userInfo = this.authService.getUserInfo();
    };
    StateService.prototype.setAgentApplications = function (agentApplications) {
        this.agentApplications = agentApplications;
    };
    StateService.prototype.getAgentApplications = function () {
        return this.agentApplications;
    };
    StateService.prototype.setToken = function (token) {
        this.token = token;
    };
    StateService.prototype.getToken = function () {
        return this.token;
    };
    StateService.prototype.setAppId = function (appId) {
        this.appId = appId;
    };
    StateService.prototype.getAppId = function () {
        return this.appId;
    };
    StateService.prototype.setAgentId = function (agentId) {
        this.agentId = agentId;
    };
    StateService.prototype.getAgentId = function () {
        return this.agentId;
    };
    StateService.prototype.ngOnDestroy = function () {
        this.destroyed$.next();
        this.destroyed$.unsubscribe();
    };
    StateService.ngInjectableDef = i0.defineInjectable({ factory: function StateService_Factory() { return new StateService(i0.inject(i1.AuthService)); }, token: StateService, providedIn: "root" });
    return StateService;
}());
export { StateService };
