import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CommissionService } from "app/iap/services/commission.service";
import { DialogService } from "app/iap/services/dialog.service";
import { UserService } from "app/iap/services/user.service";
import { AuthService } from "app/shared/auth/auth.service";
import { BehaviorSubject, Subject } from "rxjs";

export interface paramObject{
  term:any;
}

@Component({
  selector: "app-request-for-material",
  templateUrl: "./disputed-request.component.html",
  styleUrls: ["./disputed-request.component.scss"]
})
export class DisputedRequestComponent implements OnInit, OnDestroy {
  public applicationID: string;
  public agencyID: string;
  public RequestID: number;

  public agentResourceForm: FormGroup;

  public agentsList: string[];
  public programCodesList: string[];
  public termsList: any[];
  public programCampusesList: string[];
  public agentID: number;
  public agencyName: string;
  public selectedTermID: Number;
  public selectedTermOption: string;
  public term1:string;
  public loadingFlag:boolean;

  public differenceLength = new BehaviorSubject(0);

  //let observable know when to unsubscribe, will be marked as completed whenever the form is destroyed
  destroyed$ = new Subject();

  paramObject:paramObject;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private commissionService: CommissionService,
    private dialogService: DialogService,
    private authService: AuthService,
    private userService: UserService
  ) 
  {
    this.loadingFlag = false;

    this.commissionService.getProgramCodes().subscribe(data => {
      this.programCodesList = data.recordset;
    });

    this.commissionService.getTerms().subscribe(data => {
      this.termsList = data.recordset;
    });

    this.commissionService.getProgramCampuses().subscribe(data => {
      this.programCampusesList = data.recordset;
    });
  }

  public ngOnInit() {

    //this.paramObject = window.history.state.parameters;

    //let term = this.paramObject.term;

    this.agentResourceForm = this.formBuilder.group({
      agentNameListCtrl: [{value: "", disabled: true}],
      studentNumberCtrl: [""],
      applicationNumberCtrl: [""],
      termCtrl: [{value: "", disabled: true}],
      studentLastNameCtrl: [""],
      studentFirstNameCtrl: [""],
      programCodeCtrl: [""],
      programNameCtrl: [""],
      campusCtrl: [{value: "", disabled: true}],
      tuitionAmountCtrl: [""],
      commissionAmountCtrl: [""],
      commissionStatusCtrl: [""],
      commentsCtrl: [""],
      tuitionAmountCtrl2: ["", Validators.pattern(/^\d*\.?\d*$/)]
    });

    this.agentResourceForm.controls["commentsCtrl"].valueChanges.subscribe(
      v => {
        if (v)
        {
          this.differenceLength.next(500 - v.length);
        }
      }
    );

    this.route.params.subscribe((params: Params) => {
      this.applicationID = params["id"];
      this.agencyID = params["agencyID"];
      this.selectedTermOption = params["term"];
      this.term1 = params["term1"];

      this.commissionService
        .getAgentsByAgencyID(this.agencyID)
        .subscribe(data => {
          this.agentsList = data;
          this.agencyName = data[0].Agency;
        });

      let userInfo = this.authService.getUserInfo();

      this.userService.getAgentAndAgencyID(userInfo.oid).subscribe(data => {
        this.agentID = data.UserID;

      });

      this.initForm();
    });
  }

  // Intialize Form
  private initForm(): void {
    // If not a new form then populate with values

    const request = this.commissionService
      .getCommissionNewRequest(this.applicationID, this.selectedTermOption)
      .subscribe(data => {
        if (data) {
          this.applicationID = data.ApplicationID;
          this.RequestID = data.RequestID;

          this.agentResourceForm.patchValue({
            agentNameListCtrl: data.AgentName,
            studentNumberCtrl: data.SENECA_ID,
            applicationNumberCtrl: data.ApplicationID,
            termCtrl: data.Term,
            studentLastNameCtrl: data.StudentLastName,
            studentFirstNameCtrl: data.StudentFirstName,
            programCodeCtrl: data.ProgramCode,
            programNameCtrl: data.ProgramDescription,
            campusCtrl: data.ProgramCampus,
            tuitionAmountCtrl: data.TuitionAmount,
            commissionAmountCtrl: data.CommissionAmount,
            commissionStatusCtrl: data.CommissionStatusName
          });
        }
      });
  }

  public onSubmit() {
    //let termID = this.selectedTermOption;

    this.loadingFlag = true;

    let termID = this.termsList.filter(item=>item.Name == this.selectedTermOption)[0].Term;   

    this.commissionService
      .updateRequest(this.RequestID, this.agentID, termID, this.agentResourceForm.value)
      .subscribe(result => {
        if (result) {

          this.loadingFlag = true;

          this.dialogService.MessageBox("Updated", "X", 1000, "SuccessMessage");

          //this.router.navigateByUrl("/iap/commissions");

          let parameters = {term0: this.selectedTermOption, term1:this.term1};  

          this.router.navigate(["/iap/commissions"], {state: {parameters: parameters}}); 

        } 
        else 
        {
          this.loadingFlag = true;
          
          this.dialogService.MessageBox(
            "Error updating record",
            "X",
            5000,
            "ErrorMessage"
          );
        }
      });
  }

  public onCancel(): void {

    let parameters = {term0: this.selectedTermOption, term1:this.term1};  

    this.router.navigate(["/iap/commissions"], {state: {parameters: parameters}}); 
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  // class ends here
}
