import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-iap-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.css"]
})
export class ContactsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
