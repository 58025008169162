<!--
<mat-card>
   <mat-card-header>
      <mat-card-title><p mat-dialog-title>{{title}}</p></mat-card-title>
  </mat-card-header> 
  <mat-card-content>
      <div mat-dialog-content>
          <p>{{message}}</p>
      </div>
      
      
  </mat-card-content>
  <mat-card-actions>
      <div mat-dialog-actions>
          <button *ngIf="no!=='logout'" mat-button (click)="onDismiss()">{{no}}</button> 
          <button mat-raised-button color="primary" (click)="onConfirm()">{{yes}}</button>
      </div>
  </mat-card-actions>
</mat-card>
-->
<div class="seneca-dialog-container">
    <div>
      <!-- <p>{{title}}</p> -->
    </div>
    <mat-dialog-content>
      <span>{{message}}</span>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button *ngIf="no!=='logout'" mat-raised-button class="seneca-button" color="basic" (click)="onDismiss()">{{no}}</button>
      <button mat-raised-button class="seneca-button" color="primary" (click)="onConfirm()">{{yes}}</button>
    </mat-dialog-actions>
  </div>