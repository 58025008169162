import * as tslib_1 from "tslib";
import { environment } from "environments/environment";
import { fromPromise } from "rxjs-compat/observable/fromPromise";
import { AuthService } from "../auth/auth.service";
import { StateService } from "./state.service";
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
import * as i2 from "./state.service";
var IapHttpInterceptorService = /** @class */ (function () {
    function IapHttpInterceptorService(authService, stateService) {
        this.authService = authService;
        this.stateService = stateService;
        this.appId = null;
    }
    IapHttpInterceptorService.prototype.handleAccess = function (request, next, agentId, appId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var headerSettings, token, isUserAdmin, numberTime, currentNumberTime, authResponse, user;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headerSettings = {};
                        isUserAdmin = false;
                        if (this.authService.getCacheAccessToken()) {
                            numberTime = Number(this.authService.getCacheAccessToken().expiresIn);
                            currentNumberTime = Math.floor(Date.now() / 1000);
                        }
                        else {
                            console.log("access token is null");
                        }
                        if (!(this.authService.getCacheAccessToken() &&
                            Number(this.authService.getCacheAccessToken().expiresIn) >
                                Math.floor(Date.now() / 1000))) return [3 /*break*/, 1];
                        token = this.authService.getCacheAccessToken().accessToken;
                        return [3 /*break*/, 5];
                    case 1:
                        if (!environment.ssoEnabled) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.authService.acquireToken()];
                    case 2:
                        authResponse = _a.sent();
                        token = authResponse.accessToken;
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.authService.acquireToken()];
                    case 4:
                        token = _a.sent();
                        _a.label = 5;
                    case 5:
                        if (token) {
                            user = this.authService.getUserInfo();
                            if (environment.ssoEnabled) {
                                if (user.rolesOnly) {
                                    isUserAdmin = user.rolesOnly.includes("Admin");
                                }
                            }
                            agentId = this.stateService.agentId;
                            headerSettings["Authorization"] = "Bearer " + token;
                        }
                        if (environment.apiSubscriptionEnabled) {
                            headerSettings[environment.apiSubscriptionKeyName] =
                                environment.apiSubscriptionKey;
                        }
                        if (appId) {
                            headerSettings["appId"] = appId;
                        }
                        if (agentId) {
                            headerSettings["agentId"] = agentId;
                            if (environment.ssoEnabled) {
                                headerSettings["isAdmin"] = "" + isUserAdmin;
                            }
                        }
                        return [2 /*return*/, next
                                .handle(request.clone({
                                setHeaders: headerSettings
                            }))
                                .toPromise()];
                }
            });
        });
    };
    IapHttpInterceptorService.prototype.intercept = function (req, next) {
        return fromPromise(this.handleAccess(req, next, this.stateService.getAgentId(), this.stateService.getAppId()));
    };
    IapHttpInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function IapHttpInterceptorService_Factory() { return new IapHttpInterceptorService(i0.inject(i1.AuthService), i0.inject(i2.StateService)); }, token: IapHttpInterceptorService, providedIn: "root" });
    return IapHttpInterceptorService;
}());
export { IapHttpInterceptorService };
