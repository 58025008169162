import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CommissionService } from "app/iap/services/commission.service";

@Component({
  selector: "app-view-submitted-original",
  templateUrl: "./view-submitted.component.html",
  styleUrls: ["./view-submitted.component.scss"]
})
export class ViewSubmittedOriginalComponent implements OnInit {
  @Output() rowClickedEvent = new EventEmitter<boolean>();

  public applicationID: string;
  public agencyID: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commissionService: CommissionService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.applicationID = params["id"];
      this.agencyID = params["agencyID"];
    });
  }

  onCancel(): void {
    this.rowClickedEvent.emit(false);
  }
}
