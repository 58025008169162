import { Injectable, OnDestroy } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment
} from "@angular/router";
import { MsalGuard, MsalService } from "@azure/msal-angular";
import { environment } from "environments/environment";
import { commonEnv } from "environments/environment.common";
import { Observable } from "rxjs";

import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate, CanLoad, OnDestroy {
  constructor(
    private authService: AuthService,
    private msalGuard: MsalGuard,
    private msalService: MsalService
  ) {}

  // TODO:This function can be made simpler by separating the logic for initial login authentication, applicant authentication and agent authentication into 3 separate guards
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const targetUrl = state.url;

    let isAuthenticated = await this.authService.isAuthenticated();

    if (!isAuthenticated) {
      let testMatch = targetUrl.match(`/iap`);

      /*
      if (targetUrl.match(`/iap`)) {
        this.msalService.authority = environment.msalAgentConfigs.authority;
      }
      */
      return this.msalGuard.canActivate(route, state);
    }

    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (this.authService.isAuthenticated()) {
      return true;
    } else {
      return false;
    }
  }

  /* TODO: use something like this func to cleanup the canActivate function (source: Angular official documents)
  checkLogin(url: string): boolean {
    if (this.authService.isAuthenticated()) { return this.msalGuard.canActivate(route, state); }

    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;

    // Create a dummy session id
    // const sessionId = 123456789;

    // Set our navigation extras object
    // that contains our global query params and fragment
    // const navigationExtras: NavigationExtras = {
    //   queryParams: { 'session_id': sessionId },
    //   fragment: 'anchor'
    // };

    // Navigate to the login page with extras
    // this.router.navigate(['/login'], navigationExtras);
    this.router.navigate(['/']);
    return false;
  }
  */

  ngOnDestroy(): void {}
}
