import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  Sort
} from "@angular/material";

import { ApplicantsListService } from "./../../services/applicants-list.service";
import { IApplicants } from "app/iap/models/applicants-list.model";

@Component({
  selector: "app-iap-applications",
  templateUrl: "./applications.component.html",
  styleUrls: [
    "../../shared/css/commonList.css",
    "./applications.component.scss"
  ]
})
export class ApplicationsComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ELEMENT_DATA: any[];

  displayedColumns: string[] = [
    "fullName",
    "applicationNumber",
    "studentNumber",
    "term",
    "programName",
    "campus",
    "statusDate",
    "applicationStatus",
    "offerLetter"
  ];
  dataSource: MatTableDataSource<any>;

  ApplicantList: IApplicants[];

  communicationPdfLoading: boolean;

  constructor(private service: ApplicantsListService) {
    this.communicationPdfLoading = false;
  }

  ngOnInit() {
    this.refreshData();
    this.paginator.pageSize = 5;
  }

  refreshData() {
    this.service.getAllApplicantsList().subscribe((appllist: IApplicants[]) => {
      if (appllist != null) {
        this.ApplicantList = appllist;
        this.ELEMENT_DATA = appllist;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }

      // console.log(appllist);
    });
  }
  // filters
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openOfferLetter(application: any) {
    this.communicationPdfLoading = true;
    this.service
      .getOfferLetterPdf(
        application.studentNumber,
        application.applicationNumber,
        application.ApplProgNbr
      )
      .subscribe(offerLetterData => {
        this.communicationPdfLoading = false;
        offerLetterData.forEach(pdfData => {
          const linkSource = "data:application/pdf;base64," + pdfData.FILEDATA;
          const downloadLink = document.createElement("a");
          const fileName =
            application.studentNumber +
            "-" +
            application.fullName +
            "-" +
            pdfData.FILENAME;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
      });
  }
}
