import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root"
})
export class AppInsightsService {
  instance: ApplicationInsights;

  constructor() {
    if (environment.ssoEnabled) {
      this.instance = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsights.instrumentationKey,
          enableAutoRouteTracking: true,
          enableCorsCorrelation: true
        }
      });

      this.instance.loadAppInsights();
      const telemetryInitializer = envelope => {
        envelope.tags["ai.cloud.role"] = "AGENT_FRONTEND";
      };
      this.instance.addTelemetryInitializer(telemetryInitializer);
      this.instance.trackPageView();
    }
  }
}
