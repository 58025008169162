import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  ApplicationRef,
  DoBootstrap,
  ErrorHandler,
  NgModule
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import {
  MatIconModule,
  MatIconRegistry,
  MatDialogModule
} from "@angular/material";
import { BrowserModule, DomSanitizer } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import { SharedModule } from "./shared/shared.module";
import { MsalModule, MsalService } from "@azure/msal-angular";
import { getMsalConfigs } from "./shared/utils";
import { StateService } from "./shared/services/state.service";
import { MSAL_CONFIG } from "@azure/msal-angular/dist/msal.service";
import { IapHttpInterceptorService } from "./shared/services/iap-http-interceptor.service";
import { DialogComponent } from "./iap/shared/dialog/dialog.component";

//import { DialogComponent } from './iap/shared/dialog/dialog.component';
@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, DialogComponent],
  imports: [
    MatDialogModule,
    MatIconModule,
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    }),
    MsalModule,
    LoggerModule.forRoot({
      //level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
      level: NgxLoggerLevel.OFF,
      serverLogLevel: NgxLoggerLevel.OFF
    })
  ],
  providers: [
    MsalService,
    StateService,
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: IapHttpInterceptorService
    },
    {
      provide: MSAL_CONFIG,
      useFactory: getMsalConfigs
    }
  ],
  entryComponents: [AppComponent, DialogComponent]
})
export class AppModule implements DoBootstrap {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mdi.svg")
    ); // Or whatever path you placed mdi.svg at
  }

  ngDoBootstrap(ref: ApplicationRef) {
    if (window !== window.parent && !window.opener) {
    } else {
      ref.bootstrap(AppComponent);
    }
  }
}
