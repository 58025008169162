/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./icon-snack-bar.component";
import * as i2 from "@angular/material/snack-bar";
var styles_IconSnackBarComponent = [];
var RenderType_IconSnackBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconSnackBarComponent, data: {} });
export { RenderType_IconSnackBarComponent as RenderType_IconSnackBarComponent };
export function View_IconSnackBarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "flex-box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "flex-column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["src", "../../../../assets/img/check_circle_outline-24px_rounded.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u00A0"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "flex-column"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.message); _ck(_v, 5, 0, currVal_0); }); }
export function View_IconSnackBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "icon-snack-bar", [], null, null, null, View_IconSnackBarComponent_0, RenderType_IconSnackBarComponent)), i0.ɵdid(1, 49152, null, 0, i1.IconSnackBarComponent, [i2.MAT_SNACK_BAR_DATA], null, null)], null, null); }
var IconSnackBarComponentNgFactory = i0.ɵccf("icon-snack-bar", i1.IconSnackBarComponent, View_IconSnackBarComponent_Host_0, {}, {}, []);
export { IconSnackBarComponentNgFactory as IconSnackBarComponentNgFactory };
