import * as tslib_1 from "tslib";
import { OnDestroy } from "@angular/core";
import { AuthService } from "../../../shared/auth/auth.service";
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/auth/auth.service";
var UsersGuard = /** @class */ (function () {
    function UsersGuard(authService) {
        this.authService = authService;
    }
    // TODO:This function can be made simpler by separating the logic for initial login authentication, applicant authentication and agent authentication into 3 separate guards
    UsersGuard.prototype.canActivate = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.authService.isAgencyAdmin()) {
                    return [2 /*return*/, true];
                }
                else {
                    return [2 /*return*/, false];
                }
                return [2 /*return*/];
            });
        });
    };
    UsersGuard.prototype.canLoad = function (route, segments) {
        if (this.authService.isAgencyAdmin()) {
            return true;
        }
        else {
            return false;
        }
    };
    UsersGuard.prototype.ngOnDestroy = function () { };
    UsersGuard.ngInjectableDef = i0.defineInjectable({ factory: function UsersGuard_Factory() { return new UsersGuard(i0.inject(i1.AuthService)); }, token: UsersGuard, providedIn: "root" });
    return UsersGuard;
}());
export { UsersGuard };
