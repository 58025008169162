import { Component, OnInit } from "@angular/core";
import { AnnouncementService } from "../../services/announcement.service";
import { IAnnouncement } from "app/iap/models/announcement.model";
import { AuthService } from "app/shared/auth/auth.service";
import { environment } from "environments/environment";

@Component({
  selector: "app-iap-home-component",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  name: string;
  hideApplicationButton: boolean;
  userIsAdmin: boolean;
  iwaAppUrl: string;

  ELEMENT_DATA;
  editorReadSantStyle = {
    height: "100px",
    alignment: "MJ Selected-Quill"
  };
  constructor(
    private service: AnnouncementService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getData();

    var userInfo = this.authService.getUserInfo();
    this.name = userInfo.name;
    this.iwaAppUrl = environment.iwaAppUrl;

    this.hideApplicationButton = this.authService.hasPermission(
      "HideApplicationButton"
    );
    // console.log(
    //   "hideApplicationButton value is: " + this.hideApplicationButton
    // );

    this.userIsAdmin = this.authService.hasRole("Admin");
    // console.log("userIsAdmin value is: " + this.userIsAdmin);
  }

  getData() {
    this.service
      .getAll("/announcements")
      .subscribe((announcement: IAnnouncement[]) => {
        // console.log(announcement);
        this.ELEMENT_DATA = announcement;
      });
  }

  goToIwaApp() {
    window.open(this.iwaAppUrl);
  }
}
