import * as tslib_1 from "tslib";
import { OnDestroy } from "@angular/core";
import { MsalGuard, MsalService } from "@azure/msal-angular";
import { AuthService } from "./auth.service";
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@azure/msal-angular/dist/msal-guard.service";
import * as i3 from "@azure/msal-angular/dist/msal.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, msalGuard, msalService) {
        this.authService = authService;
        this.msalGuard = msalGuard;
        this.msalService = msalService;
    }
    // TODO:This function can be made simpler by separating the logic for initial login authentication, applicant authentication and agent authentication into 3 separate guards
    AuthGuard.prototype.canActivate = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var targetUrl, isAuthenticated, testMatch;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        targetUrl = state.url;
                        return [4 /*yield*/, this.authService.isAuthenticated()];
                    case 1:
                        isAuthenticated = _a.sent();
                        if (!isAuthenticated) {
                            testMatch = targetUrl.match("/iap");
                            /*
                            if (targetUrl.match(`/iap`)) {
                              this.msalService.authority = environment.msalAgentConfigs.authority;
                            }
                            */
                            return [2 /*return*/, this.msalGuard.canActivate(route, state)];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    AuthGuard.prototype.canLoad = function (route, segments) {
        if (this.authService.isAuthenticated()) {
            return true;
        }
        else {
            return false;
        }
    };
    /* TODO: use something like this func to cleanup the canActivate function (source: Angular official documents)
    checkLogin(url: string): boolean {
      if (this.authService.isAuthenticated()) { return this.msalGuard.canActivate(route, state); }
  
      // Store the attempted URL for redirecting
      this.authService.redirectUrl = url;
  
      // Create a dummy session id
      // const sessionId = 123456789;
  
      // Set our navigation extras object
      // that contains our global query params and fragment
      // const navigationExtras: NavigationExtras = {
      //   queryParams: { 'session_id': sessionId },
      //   fragment: 'anchor'
      // };
  
      // Navigate to the login page with extras
      // this.router.navigate(['/login'], navigationExtras);
      this.router.navigate(['/']);
      return false;
    }
    */
    AuthGuard.prototype.ngOnDestroy = function () { };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.AuthService), i0.inject(i2.MsalGuard), i0.inject(i3.MsalService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
