import { ApplicationRef, DoBootstrap } from "@angular/core";
import { MatIconRegistry } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { getMsalConfigs } from "./shared/utils";
var ɵ0 = getMsalConfigs;
//import { DialogComponent } from './iap/shared/dialog/dialog.component';
var AppModule = /** @class */ (function () {
    function AppModule(matIconRegistry, domSanitizer) {
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl("./assets/mdi.svg")); // Or whatever path you placed mdi.svg at
    }
    AppModule.prototype.ngDoBootstrap = function (ref) {
        if (window !== window.parent && !window.opener) {
        }
        else {
            ref.bootstrap(AppComponent);
        }
    };
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
