import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "environments/environment";
import * as i0 from "@angular/core";
var AppInsightsService = /** @class */ (function () {
    function AppInsightsService() {
        if (environment.ssoEnabled) {
            this.instance = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.appInsights.instrumentationKey,
                    enableAutoRouteTracking: true,
                    enableCorsCorrelation: true
                }
            });
            this.instance.loadAppInsights();
            var telemetryInitializer = function (envelope) {
                envelope.tags["ai.cloud.role"] = "AGENT_FRONTEND";
            };
            this.instance.addTelemetryInitializer(telemetryInitializer);
            this.instance.trackPageView();
        }
    }
    AppInsightsService.ngInjectableDef = i0.defineInjectable({ factory: function AppInsightsService_Factory() { return new AppInsightsService(); }, token: AppInsightsService, providedIn: "root" });
    return AppInsightsService;
}());
export { AppInsightsService };
