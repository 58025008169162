import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
import { ApplicantsListService } from "app/iap/services/applicants-list.service";
import { ApplicationUpdatesService } from "app/iap/services/application-updates.service";
import { AuthService } from "app/shared/auth/auth.service";

@Component({
  selector: "app-application-updates",
  templateUrl: "./application-updates.component.html",
  styleUrls: ["./application-updates.component.scss"]
})
export class ApplicationUpdatesComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    "student_name",
    "iwa_application_number",
    "student_number",
    "admit_term_description",
    "program_code",
    "campus_description",
    "action_date",
    "reason_description",
    "readStatus",
    "offer_letter"
  ];
  ELEMENT_DATA: ApplicationUpdates[] = [];
  dataSource = new MatTableDataSource<ApplicationUpdates>();
  groupedData: { [key: string]: ApplicationUpdates[] };
  expandedRow: any = null;
  communicationPdfLoading: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private authService: AuthService,
    private applicationUpdatesService: ApplicationUpdatesService,
    private router: Router,
    private pdfservice: ApplicantsListService
  ) {}

  ngOnInit() {
    this.dataSource.paginator = this.paginator;

    if (this.authService.isAgencyAdmin()) {
      let userInfo = this.authService.getUserInfo();

      this.applicationUpdatesService.getUpdatedApplications().subscribe(
        (result: ApplicationUpdates[]) => {
          this.groupedData = this.groupDataByApplicationNumber(result);
          for (const key in this.groupedData) {
            if (this.groupedData.hasOwnProperty(key)) {
              const group = this.groupedData[key];
              if (group.length > 0) {
                // Push the first element of the group into ELEMENT_DATA
                this.ELEMENT_DATA.push(group[0]);
                if (group[1] !== undefined && group[1] !== null) {
                  this.ELEMENT_DATA.push(group[1]);
                }
              }
            }
          }
          this.dataSource.data = this.ELEMENT_DATA;
          this.dataSource.sort = this.sort;
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  onRowClicked(e, row: ApplicationUpdates) {
    console.log(e.target.className.includes("mat-icon"));
    if (e.target.className.includes("mat-icon")) {
      return;
    }
    const data = this.ELEMENT_DATA.find(
      obj =>
        obj.iwa_application_number + obj.term + obj.program_code ===
        row.iwa_application_number + row.term + row.program_code
    );
    if (data) {
      this.router.navigate([
        "iap/application-updates/application-details/",
        data.iwa_application_number + data.term + data.program_code
      ]);
    } else {
      console.log("There is duplicate data in table");
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {}

  openOfferLetter(application: ApplicationUpdates) {
    this.communicationPdfLoading = true;
    this.pdfservice
      .getOfferLetterPdf(
        application.student_number,
        application.application_number,
        application.application_program_number
      )
      .subscribe(offerLetterData => {
        this.communicationPdfLoading = false;
        offerLetterData.forEach(pdfData => {
          const linkSource = "data:application/pdf;base64," + pdfData.FILEDATA;
          const downloadLink = document.createElement("a");

          const fileName =
            application.student_number +
            "-" +
            application.student_name +
            "-" +
            pdfData.FILENAME;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
      });
  }

  // Add a method to group data by 'iwa_application_number'
  groupDataByApplicationNumber(
    data: ApplicationUpdates[]
  ): { [key: string]: ApplicationUpdates[] } {
    return data.reduce((groups, item) => {
      const key = item.iwa_application_number;
      (groups[key] = groups[key] || []).push(item);
      return groups;
    }, {});
  }
}

export interface ApplicationUpdates {
  iwa_application_number: string;
  student_number: string;
  agent_id: string;
  student_name: string;
  application_number: string;
  application_program_number: Number;
  term: Number;
  admit_term_description: string;
  program_code: string;
  program_description: string;
  campus: string;
  campus_description: string;
  action_date: string;
  reason: string;
  reason_description: string;
  acad_career: string;
  acad_level: string;
  offer_letter: string;
  readStatus: Number;
}
