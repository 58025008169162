const ssoEnabled = true;

// const applicantSignInPolicy = ssoEnabled ? "" : "B2C_1_signupsignin";
// const agentSignInPolicy = ssoEnabled
//   ? "B2C_1A_signin_with_roles_ADDLOCAL-FORCERESET"
//   : "B2C_1_agentsignupsignin";
const azureInstance = ssoEnabled
  ? `https://IAPDEVB2C.b2clogin.com/tfp`
  : `https://IAPDEVB2C.b2clogin.com/tfp`;
const azureInstance_v2 = ssoEnabled ? `` : `https://IAPDEVB2C.b2clogin.com/tfp`;

// Seneca Phase 1 configuration -starts
//const applicantSignInPolicy = 'B2C_1_signupsignin';
//const agentSignInPolicy = 'B2C_1_agentsignupsignin';
//const azureInstance = `https://login.microsoftonline.com/tfp`;
//const azureInstance_v2 = `https://IAPDEVB2C.b2clogin.com/tfp`;
// Seneca Phase 1 configuration -- ends

//SSO settings - Phase 2 - starts
//const applicantSignInPolicy = '';
//const agentSignInPolicy = 'B2C_1A_signup_signin_iapb2c';
//const azureInstance = `https://iapb2c.b2clogin.com/tfp/`;
//const azureInstance_v2 = ``;
//SSO settings - Phase 2 - ends

const azureApplicantCallbackUrl = "";
const azureAgentCallbackUrl = "";

export const commonEnv = {
  ssoEnabled,
  // applicantSignInPolicy,
  // agentSignInPolicy,
  azureInstance,
  azureInstance_v2,
  azureApplicantCallbackUrl,
  azureAgentCallbackUrl
  // resetPasswordPolicy
};
