import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Http } from "@angular/http";
import {
  MatDialog,
  MatPaginator,
  MatTableDataSource,
  MatSort
} from "@angular/material";
import { Router } from "@angular/router";
import { HttpServiceAgentResource } from "app/iap/services/iap-http.service";
import {
  CustomBox,
  DialogComponent
} from "app/iap/shared/dialog/dialog.component";
import { Helper } from "app/shared/common/helper";
import { AuthService } from "app/shared/auth/auth.service";
@Component({
  selector: "app-iap-resources",
  templateUrl: "./resources-for-agencies.component.html",
  styleUrls: ["./resources-for-agencies.component.scss"]
})
export class ResourcesForAgenciesComponent implements OnInit {
  //public agentResources: IAgentResource[];
  agentResources;

  public centered: boolean;
  public disabled: boolean;
  public unbounded: boolean;
  public radius: number;
  public color: string;
  public isPageReadOnly: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ["DisplayName"];
  dataSource: MatTableDataSource<any>;
  ELEMENT_DATA;

  constructor(
    private router: Router,
    private httpServiceAgentResource: HttpServiceAgentResource,
    private http: Http,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.centered = true;
    this.disabled = false;
    this.unbounded = false;
    this.radius = 25;
    this.color = "#da291c";

    this.agentResources = null;
    this.isPageReadOnly = this.authService.isPageReadOnly("Resources");
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public ngOnInit() {
    this.paginator.pageSize = 5;

    this.httpServiceAgentResource
      .getAgentResourceRequest("resources")
      .subscribe(result => {
        this.agentResources = result;

        this.ELEMENT_DATA = result;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  public NavigateToRequestForMaterial() {
    this.router.navigate(["iap/resources/requestformaterial"]);
  }

  public IsFile(resource: any): boolean {
    return resource.SourceID === 2;
  }

  public IsLink(resource: any): boolean {
    return resource.Link !== "";
  }

  DisplayErrorMessage(dialog, dialogHeading, messages: string, no, yes) {
    const resDialog = new CustomBox(dialogHeading, messages, no, yes);

    const dialogRef = dialog.open(DialogComponent, {
      maxWidth: "600px",
      data: resDialog
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        setTimeout(() => {
          window.location.reload();
        }, 200);
      }
    });
  }

  public DownloadFile(id: number, name: string): boolean {
    return this.httpServiceAgentResource
      .checkIfFileExists("resources/checkIfFileExists", id.toString())
      .subscribe(response => {
        if (response === true) {
          this.DisplayFile(id, name);
        } else {
          this.DisplayErrorMessage(
            this.dialog,
            "Informational Message",
            "The file does not exists on the server's storage.",
            "Hidden",
            "Close"
          );

          console.log("File does not exists in the blob's storage: " + name);
        }
      });
  }

  public DisplayFile(id: number, name: string): any {
    console.log(
      "DownloadFile method has been called for the file named: " + id.toString()
    );

    return this.httpServiceAgentResource
      .downloadFile("resources/downloadFile", id.toString())
      .subscribe(response => {
        let downloadedFile = new File([response], name, {
          type: response.type
        });

        var fileExtension = name.split(".").pop();

        var contentType = Helper.GetContentType(fileExtension);

        var blob = new Blob([downloadedFile], {
          type: contentType
        });

        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        document.body.appendChild(link);

        setTimeout(() => window.open(link.href), 500);
      });
  }
}
