import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { environment, MSAL_CONFIGURATION } from "environments/environment";
import { UserAgentApplication } from "msal";
import { Observable } from "rxjs";
import { IMsalStoredToken, IUser } from "./auth.model";
//import { AppInsightsService } from "app/app-insights.service";
import { lookupService } from "dns";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  userAgent: UserAgentApplication;

  constructor(
    private msalService: MsalService,
    private http: HttpClient //,private appInsights: AppInsightsService
  ) {
    if (environment.ssoEnabled) {
      this.userAgent = new UserAgentApplication(MSAL_CONFIGURATION);
    }
  }

  acquireToken = () => {
    if (environment.ssoEnabled) {
      var newRequest = {
        scopes: [environment.clientId]
      };

      return this.userAgent.acquireTokenSilent(newRequest);
    } else {
      return this.msalService.acquireTokenSilent(environment.scopes);
    }
  };

  isAuthenticated = async (): Promise<boolean> => {
    if (environment.ssoEnabled) {
      let userInfo = this.getUserInfo() as IUser;

      /*
      if (userInfo && userInfo.rolesOnly) {
        this.appInsights.instance.setAuthenticatedUserContext(
          userInfo.sub,
          userInfo.rolesOnly[0]
        );
      } else {
        this.appInsights.instance.clearAuthenticatedUserContext();
      }
      */
    }

    return !!this.getUserInfo();
  };

  login = () => {
    if (environment.ssoEnabled) {
      var newRequest = {
        scopes: [environment.clientId]
      };

      this.msalService.loginRedirect([environment.clientId]);
    } else {
      return this.msalService.loginRedirect(environment.scopes);
    }
  };

  logout = () => {
    if (environment.ssoEnabled) {
      return this.userAgent.logout();
    } else {
      return this.msalService.logout();
    }
  };

  hasRole(name: string) {
    let retValue: boolean = false;
    let user = this.getUserInfo();

    if (user.rolesOnly) {
      user.rolesOnly.forEach(role => {
        if (role === name) {
          retValue = true;
        }
      });
    }

    return retValue;
  }

  hasPermission(name: string) {
    let retValue: boolean = false;
    let user = this.getUserInfo();

    if (user.permissionsOnly) {
      user.permissionsOnly.forEach(permission => {
        if (permission === name) {
          retValue = true;
        }
      });
    }

    return retValue;
  }

  isAdmin() {
    return this.hasRole("Admin");
  }

  isAgent() {
    return this.hasRole("Agent");
  }

  isAgencyAdmin() {
    return this.hasRole("Agency_Admin");
  }

  isCommissionsUser() {
    return this.hasRole("Agency_Commissions");
  }

  getRolesTestCase(testCase: number) {
    let roles: string = "";
    switch (testCase) {
      case 1:
        //Admin - ReadWrite access
        roles = environment.roles_case_1;
        break;
      case 2:
        //Agent-ResourcesPageReadOnly, Agent-HideApplicationButton
        roles = environment.roles_case_2;
        break;
    }

    return roles;
  }

  getUserInfo = (): IUser => {
    if (environment.ssoEnabled) {
      const userData = this.userAgent.getAccount();

      if (userData) {
        let user = userData.idToken as IUser;

        if (environment.test_roles) {
          // test roles test cases overriding roles on the client
          user.roles = this.getRolesTestCase(2);
        }

        let rolesOnly: any[] = [];
        let permissionsOnly: any[] = [];

        if (user.roles.length > 0) {
          user.roles.split(", ").forEach(role => {
            let roleSplitArray = role.split("-");

            if (roleSplitArray[0]) {
              let roleOnly = roleSplitArray[0].toString();

              if (!rolesOnly.includes(roleOnly)) {
                rolesOnly.push(roleOnly);
              }

              user.rolesOnly = rolesOnly;
            }

            if (roleSplitArray[1]) {
              let permissionOnly = roleSplitArray[1].toString();

              if (!permissionsOnly.includes(permissionOnly)) {
                permissionsOnly.push(permissionOnly);
              }
            }

            user.permissionsOnly = permissionsOnly;
          });
        }

        //log user's roles
        // console.log("user roles are:" + user.rolesOnly);

        //log user's permission
        // console.log("user permission are:" + user.permissionsOnly);

        return user;
      }
    } else {
      const userData = this.msalService.getUser();
      return userData ? (userData.idToken as IUser) : null;
    }

    return null;
  };

  isPageReadOnly(pageName) {
    let isPageReadOnly: boolean = false;

    if (environment.ssoEnabled) {
      switch (pageName) {
        case "Resources": {
          if (this.hasPermission("ResourcesPageReadOnly")) {
            isPageReadOnly = true;
          }
          break;
        }
        default:
          break;
      }

      // console.log(
      //   '"' +
      //     pageName +
      //     '"' +
      //     " page " +
      //     " readOnly property is: " +
      //     isPageReadOnly
      // );
    }

    return isPageReadOnly;
  }

  verifyAgent(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/verifyAgent`);
  }

  getCacheAccessToken = (): IMsalStoredToken => {
    if (environment.ssoEnabled) {
      let scopes = environment.scopes;

      // console.log(
      //   "this.msalService.getUser().userIdentifier is: " +
      //     this.msalService.getUser().userIdentifier
      // );

      const key = {
        authority: this.msalService.authority,
        clientId: environment.clientId,
        scopes: scopes[0],
        userIdentifier: this.msalService.getUser().userIdentifier
      };

      const storageValue = localStorage.getItem(JSON.stringify(key));

      return storageValue ? JSON.parse(storageValue) : null;
    } else {
      let scopes = [environment.clientId];

      const key = {
        authority: this.msalService.authority,
        clientId: environment.clientId,
        scopes: scopes[0],
        userIdentifier: this.msalService.getUser().userIdentifier
      };

      const storageValue = sessionStorage.getItem(JSON.stringify(key));

      return storageValue ? JSON.parse(storageValue) : null;
    }
  };
}
