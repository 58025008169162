import { BuildEnvironment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Cacheable } from "./cacheable.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HttpCommonService = /** @class */ (function () {
    function HttpCommonService(http) {
        this.http = http;
        this.cachedData = new Cacheable();
        //console.log("service initialization type of:" + typeof (this.cachedData));
    }
    HttpCommonService.prototype.getFile = function (url, parameters) {
        var buildApiUrl = BuildEnvironment.GetEnvironmentUrl();
        var apiUrl = buildApiUrl + "/" + url;
        if (parameters) {
            apiUrl = buildApiUrl + "/" + url + "&" + parameters;
        }
        console.log("execute http:get for this api: " + apiUrl);
        return this.http.get(apiUrl, { responseType: "blob" });
    };
    HttpCommonService.prototype.get = function (url, parameters) {
        //console.log("execute http:get for this api: " + url);
        var buildApiUrl = BuildEnvironment.GetEnvironmentUrl();
        var apiUrl = buildApiUrl + "/" + url;
        if (parameters) {
            apiUrl = buildApiUrl + "/" + url + "&" + parameters;
        }
        return this.http.get(apiUrl);
    };
    HttpCommonService.prototype.post = function (url, parameters) {
        //console.log("execute http:post for this api: " + url);
        var buildApiUrl = BuildEnvironment.GetEnvironmentUrl();
        var apiUrl = buildApiUrl + "/" + url;
        return this.http.post(apiUrl, parameters);
    };
    HttpCommonService.prototype.getHttpData = function (url, objectName) {
        //console.log("execute getHttpData:get for this api: " + url);
        var _this = this;
        var buildApiUrl = BuildEnvironment.GetEnvironmentUrl();
        var apiUrl = buildApiUrl + "/" + url;
        this.cachedData.getHandler = function () {
            return _this.http.get(apiUrl).map(function (r) {
                if (objectName)
                    return r[objectName];
                return r;
            });
        };
        return this.getCachedData();
    };
    HttpCommonService.prototype.getCachedData = function () {
        //console.log("execute getCachedData");
        return this.cachedData.getData();
    };
    HttpCommonService.prototype.refresh = function () {
        //console.log("execute refresh");
        return this.cachedData.refresh();
    };
    HttpCommonService.ngInjectableDef = i0.defineInjectable({ factory: function HttpCommonService_Factory() { return new HttpCommonService(i0.inject(i1.HttpClient)); }, token: HttpCommonService, providedIn: "root" });
    return HttpCommonService;
}());
export { HttpCommonService };
