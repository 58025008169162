import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
//---------dialog------------------/
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import {
  CustomBox,
  DialogComponent
} from "../../shared/dialog/dialog.component";

//---------service--------//
import { NewsfeedService } from "../../services/newsfeed.service";
import { INewsfeed } from "../../models/newsfeed.model";

export interface PeriodicElement {
  title: string;
  headline: string;
  publishDate?: string;
}

@Component({
  selector: "app-newsfeedlist",
  templateUrl: "./newsfeedlist.component.html",
  styleUrls: ["./newsfeedlist.component.scss"]
})
export class NewsfeedlistComponent implements OnInit {
  ELEMENT_DATA;
  //dataSource;

  News;
  NewsfeedList: INewsfeed[];
  //@ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["Title"];
  dataSource: MatTableDataSource<any>;

  constructor(private service: NewsfeedService, public dialog: MatDialog) {}
  ngOnInit() {
    this.refreshData();
    this.paginator.pageSize = 5;
  }

  refreshData() {
    this.service.getAllNewsfeed().subscribe((newsfeed: INewsfeed[]) => {
      this.NewsfeedList = newsfeed;
      this.ELEMENT_DATA = newsfeed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  deleteNewsfeed(id) {
    console.log(id);
    if (id > 0) {
      this.service.deleteNewsfeed(id);
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  //--------------dialog-------//
  openDialog(id): void {
    console.log(id);
    const message = `Are you sure you want to delete?`;
    let no = "No";
    let yes = "Yes";
    const resDialog = new CustomBox("Confirm Action", message, no, yes);

    const dialogRef = this.dialog.open(DialogComponent, {
      maxWidth: "600px",
      data: resDialog
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.deleteNewsfeed(id);
      }
    });
  }
}
