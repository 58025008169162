import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, Subject } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { IUser } from "../auth/auth.model";

@Injectable({
  providedIn: "root"
})
export class StateService implements OnInit, OnDestroy {
  destroyed$ = new Subject();
  loadingUserInfo: boolean;
  userInfo: IUser;
  appId: string;
  agentId: string;
  agentApplications: any;
  token: string;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  setUserInfo(userInfo: any) {
    this.userInfo = this.authService.getUserInfo();
  }

  setAgentApplications(agentApplications: any) {
    this.agentApplications = agentApplications;
  }

  getAgentApplications() {
    return this.agentApplications;
  }

  setToken(token: string) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  setAppId(appId: string) {
    this.appId = appId;
  }

  getAppId() {
    return this.appId;
  }

  setAgentId(agentId: string) {
    this.agentId = agentId;
  }

  getAgentId() {
    return this.agentId;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
