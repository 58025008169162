import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, NavigationEnd } from "@angular/router";
import { MatSnackBar} from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map, takeUntil} from 'rxjs/operators';
import { DomSanitizer } from "@angular/platform-browser";
import { IconSnackBarComponent } from '../../../../shared/components/icon-snack-bar/icon-snack-bar.component'

@Component({
  selector: "app-thank-you",
  templateUrl: "./thank-you.component.html",
  styleUrls: ["./thank-you.component.scss"]
})
export class ThankYouComponent implements OnInit, AfterViewInit, OnDestroy {

  isHandset$: Observable<boolean>;
  handheld: boolean;
  destroyed$: any;

  // dec 20 build 8
  constructor(private _formBuilder: FormBuilder, 
    private router: Router, private _snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private domSanitizer: DomSanitizer)
     {
      this.destroyed$ = new Subject();

      this.handheld = false;

      this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map(result => 
          {
            this.handheld = result.matches;

            if (this.handheld === true)
            {
              this.openMobileSnackBar("Saved successfully.","");
            }
            else
            {
              this.openSnackBar("Saved successfully.","");
            }
            return result.matches;
          }),
          takeUntil(this.destroyed$)
      );
    }

  ngOnInit() {

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      const contentContainer =
        document.querySelector(".mat-sidenav-content") || window;
      contentContainer.scrollTo(0, 0);
    });
  }

  ngAfterViewInit() {
    console.log("ngAfterViewInit: before calling scrollTo");

    const contentContainer =
      document.querySelector(".mat-sidenav-content") || window;
    contentContainer.scrollTo(0, 0);

    console.log("ngAfterViewInit: after calling scrollTo");
  }

  public  openMobileSnackBar(message: string, action: string) {

    this._snackBar.openFromComponent(IconSnackBarComponent, {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition:'center',
      panelClass: 'mobile-snackbar',
      data: {
        message: message,
        icon: 'done'
      }
    });

   
  }

  public  openSnackBar(message: string, action: string) {

    this._snackBar.openFromComponent(IconSnackBarComponent, {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition:'center',
      panelClass: 'mat-simple-snackbar',
      data: {
        message: message,
        icon: 'done'
      }
    });

  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

}
