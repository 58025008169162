import { Component, OnInit } from "@angular/core";
import { AnnouncementService } from "../../services/announcement.service";
import { IAnnouncement } from "app/iap/models/announcement.model";
import { AuthService } from "app/shared/auth/auth.service";

@Component({
  selector: "app-announcement",
  templateUrl: "./announcement.component.html",
  styleUrls: ["./announcement.component.scss"]
})
export class AnnouncementComponent implements OnInit {
  name: string;

  ELEMENT_DATA;
  constructor(
    private service: AnnouncementService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    var userInfo = this.authService.getUserInfo();
    this.name = userInfo.name;

    this.getData();
  }

  getData() {
    this.service
      .getAll("/announcements")
      .subscribe((announcement: IAnnouncement[]) => {
        console.log(announcement);
        this.ELEMENT_DATA = announcement;
      });
  }
}
