import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CommissionService } from "app/iap/services/commission.service";

@Component({
  selector: "app-view-submitted",
  templateUrl: "./view-submitted.component.html",
  styleUrls: ["./view-submitted.component.scss"]
})
export class ViewSubmittedComponent implements OnInit {
  @Output() rowClickedEvent = new EventEmitter<boolean>();

  public applicationID: string;
  public agencyID: string;
  public requestTypeID: string;
  public selectedTermOption:string;
  public term0:string;

  public agentResourceForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private commissionService: CommissionService
  ) {}

  ngOnInit() {
    this.agentResourceForm = this.formBuilder.group({
      studentNumberCtrl: [""],
      agentNameListCtrl: [""],
      campusCtrl: [""],
      tuitionAmountCtrl: [""],
      commissionAmountCtrl: [""],
      commissionStatusCtrl: [""],
      studentNameCtrl: [""],
      termCtrl: [""],
      programNameCtrl: [""],
      companyNameCtrl: [""],
      countryNameCtrl: [""],
      submittedDateCtrl: [""],
      voucherNumberCtrl: [""],
      approvedByNameCtrl: [""],
      disputtedCommentsCtrl: [""],
      paymentDescriptionCtrl: [""],
      senecaCommentsCtrl: [""],
      approvedDateCtrl: [""]
    });

    this.route.params.subscribe((params: Params) => {
      this.applicationID = params["id"];
      this.agencyID = params["agencyID"];
      this.requestTypeID = params["requestTypeID"];
      this.selectedTermOption = params["term"];
      this.term0 = params["term0"];

      this.initForm();
    });
  }

  // Intialize Form
  private initForm(): void {
    // If not a new form then populate with values

    const request = this.commissionService
      .getCommissionSubmittedRequest(this.requestTypeID, this.applicationID,this.agencyID)
      .subscribe(data => {
        if (data) {
          this.agentResourceForm.patchValue({
            studentNumberCtrl: data.SENECA_ID,
            agentNameListCtrl: data.AgentName,
            campusCtrl: data.ProgramCampus,
            tuitionAmountCtrl: data.TuitionAmount,
            commissionAmountCtrl: data.CommissionAmount,
            commissionStatusCtrl: data.CommissionStatusName,
            studentNameCtrl: data.StudentName,
            termCtrl: data.Term,
            programNameCtrl: data.ProgramDescription,
            companyNameCtrl: data.CompanyName,
            countryNameCtrl: data.CountryName,
            submittedDateCtrl: data.SubmittedDate,
            voucherNumberCtrl: data.VoucherNumber,
            approvedByNameCtrl: data.ApprovedByName,
            disputtedCommentsCtrl: data.DisputtedComments,
            paymentDescriptionCtrl: data.Payment_Description,
            senecaCommentsCtrl: data.senecaComments,
            approvedDateCtrl: data.ApprovedDate
          });
        }
      });
  }

  getControlLabel(type: string) {
    return this.agentResourceForm.controls[type].value;
  }

  onCancel(): void {
    //this.rowClickedEvent.emit(false);

    let parameters = {term0:this.term0, term1: this.selectedTermOption, index:1};  

    this.router.navigate(["/iap/commissions"], {state: {parameters: parameters}}); 
  }
}
